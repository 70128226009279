import React, { useEffect, useState } from "react";

//tool
import styled from "styled-components";

//global
import colors from "../../globals/colors";

//components
import Header from "../../components/Header";

//tools
import Amplitude from "amplitude-js";
import { setMetaTags } from "../../utils/metaTag";

//time
import moment from "moment";
import "moment/locale/ko";

//images
import NoticeImage1 from "../../assets/images/notice/notice_img1.png";
import NoticeImage2 from "../../assets/images/notice/notice_img2.png";
import NoticeImage3 from "../../assets/images/notice/notice_img3.png";

import data from "../../globals/notice";

const NoticeDetail = ({ history, location, match }) => {
  const [notice, setNotice] = useState({
    title: "",
    date: new Date(),
    key: "0000",
    contents: [],
  });

  useEffect(() => {
    const index = location.pathname.substring(15);

    // 공지사항 세부페이지 pathname을 list index에서 key값을 기준으로 동작하게 변경
    setNotice(data.find((item) => item.key === index));

    document.title = "공지사항 | 페이워크";

    setMetaTags({
      title: "공지사항 | 페이워크",
      description: "",
    });
  }, []);

  const viewSplitLine = (content) => {
    const spaceRegex = /(<br>|<br\/>|<br \/>)/g;

    if (!content) return <div>{content}</div>;
    const replace = (content) => {
      let convertContent = content.replace(spaceRegex, function (contents) {
        return "<br/>" + contents;
      });
      const htmlArr = [];
      convertContent.split("\n").forEach(function (text) {
        const textHtml = "<p>" + text + "</p>";
        htmlArr.push(textHtml);
      });

      return { __html: htmlArr.join("") };
    };

    return (
      <div>
        <div dangerouslySetInnerHTML={replace(content)}></div>
      </div>
    );
  };

  return (
    <Container>
      <Inner>
        <NoticeTitle>{notice.title}</NoticeTitle>
        <NoticeDate>
          {moment(notice.date).format("YYYY년 M월 D일 dd요일")}
        </NoticeDate>

        <NoticeDescription>{notice.descriptionTop}</NoticeDescription>

        {notice.isImage ? (
          <ImageWrapper>
            <Picture>
              <NoticeImage src={NoticeImage1} alt="notice image" />
            </Picture>
            <Picture>
              <NoticeImage src={NoticeImage2} alt="notice image" />
            </Picture>
            <Picture>
              <NoticeImage src={NoticeImage3} alt="notice image" />
            </Picture>
          </ImageWrapper>
        ) : (
          notice.contents.map((contents, index) => {
            return (
              <ContentsContainer>
                <ContentsTitle>{contents.title}</ContentsTitle>
                <ContentsBackground>
                  <ContentsText>
                    {viewSplitLine(contents.contents)}
                  </ContentsText>
                  {contents.substring !== "" && (
                    <ContentsSubText>{contents.substring}</ContentsSubText>
                  )}
                </ContentsBackground>
              </ContentsContainer>
            );
          })
        )}
        <NoticeDescription>
          {viewSplitLine(notice.descriptionBottom)}
        </NoticeDescription>
      </Inner>
    </Container>
  );
};

export default NoticeDetail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 360px);
  @media ${(props) => props.theme.mobile} {
  }
`;

const Inner = styled.div`
  /* width: 100%; */
  display: block;
  flex-direction: row;
  justify-content: center;
  padding: 4px 16px;
  @media ${(props) => props.theme.mobile} {
    display: block;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  flex-shrink: 1;
  flex-direction: column;
  align-items: stretch;
`;
const Picture = styled.picture`
  display: block;
  height: auto;
  width: 100%;
`;

const NoticeImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const Title = styled.div`
  font-size: 42px;
  font-weight: 600;
  color: ${colors.Body00};
  @media ${(props) => props.theme.tablet} {
    font-size: 34px;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 24px;
  }
`;

const InnerContainer = styled.div`
  /* width: 100%; */
  margin-top: 100px;
  @media ${(props) => props.theme.tablet} {
    margin-top: 60px;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 40px;
  }
`;

const NoticeItem = styled.div`
  padding: 20px 16px;
  border-top: 1px solid ${colors.G7};
`;

const NoticeTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.3px;
  color: #28272f;

  margin: 0px 0px 10px 0px;
`;

const NoticeDate = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #adb5bd;

  margin: 0px 0px 40px 0px;
`;

const NoticeDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin: 0px 0px 40px 0px;
`;

const ContentsContainer = styled.div`
  margin: 0px 0px 30px 0px;
`;

const ContentsTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #28272f;

  margin-bottom: 12px;
`;

const ContentsBackground = styled.div`
  background-color: #f5f8ff;
  border-radius: 5px;
  padding: 12px;
`;

const ContentsText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.3px;

  color: #28272f;

  highlight {
    font-weight: bold;
    color: #F00;
    text-decoration: underline;
  }
`;

const ContentsSubText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;

  color: #495057;
  /* border-top: 1px solid ${colors.B8}; */
  padding: 10px 0px 0px 0px;

  highlightRegular {
    color: #F00;
    text-decoration: underline;
  }
  highlight {
    font-weight: bold;
    color: #F00;
    text-decoration: underline;
  }
`;

const RowEvent = styled.div`
  /* width: 100%; */
  margin-bottom: 60px;
  display: flex;
  flex-direction: row;
  .event-end {
    margin-left: 20px;
  }
  .event-with-ongoing {
    cursor: pointer;
    margin-right: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    flex-direction: column;
    margin-bottom: 40px;
    .event-end {
      margin-top: 40px;
      margin-left: 0px;
    }
    .event-with-ongoing {
      margin-right: 0px;
      margin-bottom: 40px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 60px;
    .event-end {
      margin-top: 60px;
      margin-left: 0px;
    }
  }
`;

const EachEvent = styled.div`
  flex: 1;
  .hover-transform {
    transform: scale(1);
    transition: transform 0.55s 0.2s ease-out, opacity 0.55s 0.2s linear;
  }
  &:hover {
    .hover-transform {
      transform: scale(1.05);
      transition: transform 0.55s 0.2s ease-out, opacity 0.55s 0.2s linear;
    }
  }
`;

const ImageBox = styled.div`
  overflow: hidden;
  border-radius: 30px;
`;

const EventImage = styled.img`
  display: block;
  width: 100%;
  @media ${(props) => props.theme.mobile} {
    max-width: 335px;
  }
`;

const EventTitle = styled.div`
  margin-top: 20px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: ${colors.Body00};
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media ${(props) => props.theme.mobile} {
    font-size: 16px;
    line-height: 24px;
    height: 24px;
  }
`;

const EventInfo = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  @media ${(props) => props.theme.mobile} {
    margin-top: 12px;
  }
`;

const EventStatus = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.B2};

  &.black {
    color: ${colors.Body00};
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 12px;
  }
`;

const EventDueDate = styled.div`
  margin-left: 6px;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.G3};
  @media ${(props) => props.theme.mobile} {
    font-size: 12px;
    margin-left: 4px;
  }
`;
