export const setMetaTags = ({ title="견적서 페이워크", description="깔끔하고 눈에 확 띄는 견적서, 모바일에서 빠르게 만들고 수정 및 공유하세요!", imageUrl="기본 사이트 이미지 경로" }) => { 
    //set title 
    document 
    .querySelector('meta[property="og:title"]') 
    .setAttribute("content", `${title}`); 

    //set description 
    document 
    .querySelector('meta[property="og:description"]') 
    .setAttribute("content", description);
    
    //set url 
    document 
    .querySelector('meta[property="og:url"]') 
    .setAttribute("content", window.location.href); 
};

