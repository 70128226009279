import amplitude from "amplitude-js";
import * as constants from "../globals/constants";

export const initAmplitude = () => {
  amplitude.getInstance().init(constants.AMPLITUDE_API_KEY, null, {
    includeGclid: true,
    includeUtm: true,
    includeReferrer: true
  });
};

export const setAmplitudeUserDevice = (installationToken) => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};
