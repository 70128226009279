import React, { useState } from "react";

// globals
import colors from "../../../globals/colors";

// images
import DownImage from "../assets/ic_line_angle_right_f.png";
import CheckImage from "../assets/check_on.png";
import BillingImage from "../assets/billing_plan.png";
import LitePlanIcon from "../assets/lite_plan_icon.png";

// tools
import styled from "styled-components";
import { keyframes } from "styled-components";

const Main = () => {
  const freeBillingList = [
    "모든 기본 기능 무료",
    "거래 문서 작성과 관리 기능 무제한",
    "견적서, 거래명세서, 영수증, 청구서 변환",
    "사인, 도장, 명함, 로고, 브랜딩 모두 무제한",
  ];
  const liteBillingList = [
    "거래 문서 공유(카카오톡&이메일) 월 20건",
    "상대방 확인 시 알림 수신",
    "상대방의 문서 열람 횟수와 시간 확인",
  ];
  const standardBillingList = [
    "거래문서 무제한 생성",
    "전자 세금계산서 월 5건 발행",
    "전자현금영수증 무제한 발행",
    "카카오톡 월 30건, 이메일 월 10건 공유",
    "PDF, 엑셀, 이미지, 링크 무제한 공유",
    "사인, 도장 삽입",
    "상대방에게 서명 요청",
    "담당자 관리, 거래문서별 담당자 설정",
    "로고, 슬로건 추가",
    "거래문서 디자인, 색상 변경",
    "품목 일괄 엑셀 업로드 (웹에서만 가능)",
  ];

  const [isQna, setIsQna] = useState([false, false, false]);

  const onChangeIsQna = (index) => {
    const newArr = Array.from(isQna);
    newArr[index] = !newArr[index];
    setIsQna(newArr);
  };
  const onClickBillingButton = () => {
    window.open("https://app.paywork.io", "_blank");
  };
  return (
    <Container>
      <InnerContainer>
        <TitleText>
          1개월 무료로&nbsp;
          <span>
            <br />
          </span>
          밖에서도,
          <br />
          이동 중에도&nbsp;
          <span>
            <br />
          </span>
          편리하게 이용해보세요!
        </TitleText>
        <BillingContainer>
          {/* <BillingContent>
            <BillingInnerContainer>
              <BillingTitle>무료</BillingTitle>
              <BillingPriceContainer>
                <BillingPrice>
                  <b>
                    0원<span>/월</span>
                  </b>
                </BillingPrice>
                <BillingButton
                  className="free-billing"
                  onClick={onClickBillingButton}
                >
                  요금제 시작하기
                </BillingButton>

                <BillingInfoContainer>
                  {freeBillingList.map((billng) => {
                    return (
                      <BillingInfoContent>
                        <span className="icon" />
                        <span className="text">{billng}</span>
                      </BillingInfoContent>
                    );
                  })}
                </BillingInfoContainer>
              </BillingPriceContainer>
            </BillingInnerContainer>
          </BillingContent> */}
          {/* <BillingContent>
            <BillingInnerContainer>
              <BillingTitle className="lite-title">
                혼자 일해요
                <span />
              </BillingTitle>
              <BillingPriceContainer>
                <BillingPrice>
                  <span className="discount-price">5,500원</span>
                  <b>
                    3,300원<span>/월</span>
                  </b>
                </BillingPrice>
                <BillingButton
                  className="light-billing"
                  onClick={onClickBillingButton}
                >
                  요금제 시작하기
                </BillingButton>
                <BillingInfoContainer>
                  <BillingPlusText>무료의 모든 기능 +</BillingPlusText>
                  {liteBillingList.map((billng) => {
                    return (
                      <BillingInfoContent>
                        <span className="icon" />
                        <span className="text">{billng}</span>
                      </BillingInfoContent>
                    );
                  })}
                </BillingInfoContainer>
              </BillingPriceContainer>
            </BillingInnerContainer>
          </BillingContent> */}
          <BillingContent>
            <BillingInnerContainer>
              <BillingTitle className="standard-title">
                1인 사업장 전용 요금제
              </BillingTitle>
              <BillingPriceContainer>
                <BillingPrice>
                  <span className="discount-price">13,900원</span>
                  <b>
                    9,900원<span>/월</span>
                  </b>
                </BillingPrice>
                <BillingButton
                  className="light-billing"
                  onClick={onClickBillingButton}
                >
                  요금제 시작하기
                </BillingButton>
                <BillingInfoContainer>
                  <BillingPlusText>라이트의 모든 기능 +</BillingPlusText>
                  {standardBillingList.map((billng) => {
                    return (
                      <BillingInfoContent>
                        <span className="icon" />
                        <span className="text">{billng}</span>
                      </BillingInfoContent>
                    );
                  })}
                </BillingInfoContainer>
              </BillingPriceContainer>
            </BillingInnerContainer>
          </BillingContent>
        </BillingContainer>

        <TextInfoContent>
          {
            "* 기본으로 제공되는 거래문서 공유 건수 (카카오톡 월 30건, 메일 월 10건)이 "
          }
          {
            "소진되면, 자동으로 카카오톡은 10건에 2,000원, 이메일은 5건에 2,000원이 "
          }
          {
            "다음달 요금제 결제일에 부가됩니다. 모든 요금제는 부가세가 포함된 금액입니다."
          }
        </TextInfoContent>

        <FaqContainer>
          <h1>자주 묻는 질문</h1>
          <FaqContent>
            <QuestionContent
              isQna={isQna[0]}
              onClick={() => {
                window.wcs.event("price", "FAQ1");
                onChangeIsQna(0);
              }}
            >
              <span>
                {"1. 세금계산서를 더 발행하고 싶어요. 어떻게 해야 하나요?"}
              </span>
              <button />
            </QuestionContent>
            {isQna[0] && (
              <AnswerContent>
                {
                  "기본으로 제공되는 세금계산서 건수가 모두 소진되면 자동으로 10건 (2,000원)씩 충전되며, "
                }
                {
                  "다음달 요금제 결제일에 부가됩니다. 모든 요금제는 부가세가 포함된 금액입니다."
                }
              </AnswerContent>
            )}

            <QuestionContent
              isQna={isQna[1]}
              onClick={() => {
                window.wcs.event("price", "FAQ2");
                onChangeIsQna(1);
              }}
            >
              <span>
                {
                  "2. 카카오톡, 이메일로 상대방에게 더 보내고 싶어요. 어떻게 해야 하나요?"
                }
              </span>
              <button />
            </QuestionContent>
            {isQna[1] && (
              <AnswerContent>
                {
                  "기본으로 제공되는 공유 건수 (카카오톡 월 30건, 이메일 월 10건)이 소진되면, "
                }
                {
                  "자동으로 카카오톡은 10건에 2,000원, 이메일은 5건에 2,000원이 다음달 요금제 결제일에 부가됩니다. "
                }
                {"모든 요금제는 부가세가 포함된 금액입니다."}
              </AnswerContent>
            )}
          </FaqContent>
        </FaqContainer>
      </InnerContainer>
    </Container>
  );
};

export default Main;

const transform = keyframes`
	0% {
        opacity: 0;
    	transform: translateY(50px);
    }
    100%{
        opacity: 1;
    	transform: translateY(0);
    }
`;

const Container = styled.div`
  padding: 218px 0 184px;
`;
const InnerContainer = styled.div`
  padding: 0 100px;
  margin: 0 auto;
  max-width: 1440px;

  @media ${(props) => props.theme.tablet} {
    padding: 0 50px;
  }
`;
const TitleText = styled.div`
  color: ${colors.Body00};
  font-size: 42px;
  font-weight: bold;
  text-align: center;

  opacity: 0;
  animation: ${transform} 1s 1s;
  animation-fill-mode: forwards;

  span {
    display: none;
  }
  @media ${(props) => props.theme.tablet} {
    span {
      display: block;
      height: 0;
    }
  }
`;
const BillingContainer = styled.div`
  margin: 60px 0 20px;
  display: flex;
  min-height: 716px;

  opacity: 0;
  animation: ${transform} 1.5s 1.5s;
  animation-fill-mode: forwards;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const BillingContent = styled.div`
  position: relative;
  padding: 0;
  margin: 0 27px 0 0;
  flex: 1;
  background: #fff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  &:nth-child(2) {
    border: 3px solid transparent;
    background-image: linear-gradient(#fff, #fff),
      linear-gradient(to bottom, #3d5fff 0%, #5136ff 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  &:last-child {
    margin: 0;

    img {
      position: absolute;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      height: 300px;
      z-index: 99;
    }
  }

  @media (max-width: 900px) {
    margin: 0 0 20px 0;
    width: 100%;
  }
`;
const BillingBgCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  z-index: 9;
`;
const BillingInnerContainer = styled.div`
  padding: 0 20px 30px 20px;
`;
const BillingTitle = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  color: ${colors.Body00};
  font-size: 30px;
  font-weight: 600;
  border-bottom: 1px solid #e9e9ea;

  &.lite-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      width: 64px;
      height: 26px;
      background-image: url(${LitePlanIcon});
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`;
const BillingPriceContainer = styled.div``;
const BillingPrice = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 114px;
  height: ${(props) => (props.isNoButton ? "166px" : "114px")};
  padding-bottom: ${(props) => props.isNoButton && "52px"};

  .discount-price {
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
    color: ${colors.G4};
    font-size: 18px;
    line-height: 1;
    font-weight: 600;
    text-decoration: line-through;
  }

  b {
    color: ${colors.Body00};
    font-size: 34px;
    font-weight: 600;

    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
const BillingButton = styled.button`
  width: 100%;
  height: 52px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border-radius: 10px;
  border: 0;
  cursor: pointer;

  &.free-billing {
    border: 3px solid ${colors.G1};
    background-color: ${colors.G1};
  }
  &.light-billing {
    border: 3px solid #356eff;
    background-color: #356eff;
  }
  &.standard-billing {
    color: #356eff;
    border: 3px solid #356eff;
    background-color: #fff;
    z-index: 999;
  }
`;
const BillingInfoContainer = styled.div`
  margin-top: 30px;
`;
const BillingPlusText = styled.div`
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(92.37deg, #356eff 8.25%, #5135ff 51.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
const BillingInfoContent = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    display: inline-block;
  }
  .icon {
    width: 24px;
    height: 24px;
    background-image: url(${CheckImage});
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .text {
    //padding-top: 3px;
    flex: 1;
    color: ${colors.mainG};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;
const TextInfoContent = styled.div`
  color: ${colors.G3};
  font-size: 14px;
  font-weight: 400;

  opacity: 0;
  animation: ${transform} 1.5s 1.5s;
  animation-fill-mode: forwards;
`;
const FaqContainer = styled.div`
  margin-top: 150px;
  padding: 100px 30px;
  //height: 774px;
  background-color: #f9f9f9;
  border-radius: 50px;

  opacity: 0;
  animation: ${transform} 1.5s 1.5s;
  animation-fill-mode: forwards;

  h1 {
    margin: 0;
    padding: 0;
    color: ${colors.Body00};
    font-size: 30px;
    text-align: center;
  }
`;
const FaqContent = styled.div`
  margin-top: 60px;
`;
const QuestionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 12px;
  padding: 20px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  background-color: #363742;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  cursor: pointer;

  &:first-child {
    margin: 0 0 12px;
  }

  button {
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url(${DownImage});
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    transform: ${(props) => (props.isQna ? "rotate(0)" : "rotate(180deg)")};
  }
`;
const AnswerContent = styled.div`
  padding: 20px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
