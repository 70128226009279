import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { homeRoute } from "./router/allRoutes";

// global
import colors from "./globals/colors";
import ScrollToTop from "./globals/scrollToTop";

// style
import theme from "./style/theme";

// components
import Footer from "./components/Footer";

// tool
import { ThemeProvider } from "styled-components";

function App() {
  const styling = `
      color:white;
      padding: 8px;
      font-weight: 600;
      font-family: Pretendard;
  `;

  const styling2 = `
    color:white;
    background-color: ${colors.mainG};
    border-radius: 3px;
    padding: 8px;
    font-weight: 600;
    font-family: Roboto Courier;
    font-size: 30px; 
    border-top: 1px solid white; 
    border-bottom: 1px solid ${colors.B2}; 
    border-right: 1px solid ${colors.B2};
`;

  console.log(`%c P A Y W O R K `, `${styling2} `);
  console.log(
    `%c간편한 금전거래, 시세조회부터 정산요청까지\n\n\n말하기 어려운 돈거래, 안전하고 편리하게\n프리랜서, 특수고용근로자를 위한 업무, 정산 관리 앱`,
    `${styling} 
  font-size: 20px;`
  );
  console.log(
    `%chttps://bit.ly/3uQlp2M\n페이워크의 vision은 미래의 근로 문화를 만드는 것이고,\nmission은 누구나 즐겁고 생산적이게 일할 수 있도록 하는 것 입니다`,
    `${styling} 
    font-size: 15px;`
  );

  const Layout = ({ component: Component }) => (
    <Route
      exact
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop>
            <Switch>
              {homeRoute.map((route, idx) => (
                <Layout
                  path={route.path}
                  component={route.component}
                  key={idx}
                />
              ))}
            </Switch>
          </ScrollToTop>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
