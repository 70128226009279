import { Redirect } from "react-router-dom";

// components
import About from "../pages/about";
import Billing from "../pages/billing";
import Event from "../pages/event";
import Service from "../pages/service";
import Notice from "../pages/notice";
import NoticeDetail from "../pages/noticeDetail";

export const homeRoute = [
  { path: "/pricing", component: Billing },
  { path: "/event", component: Event },
  { path: "/about", component: About },
  { path: "/notice", component: Notice },
  { path: "/notice-detail/:id", component: NoticeDetail },
  { path: "/", component: Service },
  { path: "/", component: () => <Redirect to="/" /> },
];
