import React from "react";

//tool
import styled, { css } from "styled-components";

//global
import colors from "../../../globals/colors";

const Way = (props) => {
  const { wayAnimation, innerWidth } = props;
  let contentTitleFontSize;
  let contentTitleLineHeight;

  if (innerWidth > 1300) {
    contentTitleFontSize = 74;
    contentTitleLineHeight = 74;
  } else if (innerWidth <= 1300 && innerWidth > 744) {
    contentTitleFontSize = 74 - 33 * ((1300 - innerWidth) / (1300 - 744));
    contentTitleLineHeight = 74 - 33 * ((1300 - innerWidth) / (1300 - 744));
  } else if (innerWidth <= 744 && innerWidth > 375) {
    contentTitleFontSize = 74 - 34 * ((744 - innerWidth) / (744 - 375));
    contentTitleLineHeight = 74 - 24 * ((744 - innerWidth) / (744 - 375));
  } else {
    contentTitleFontSize = 40;
    contentTitleLineHeight = 40;
  }
  return (
    <Container>
      <Title isView={wayAnimation[0]}>페이워크가 일하는 법</Title>
      <FirstInnerContainer>
        <ContentTitle
          contentTitleFontSize={contentTitleFontSize}
          contentTitleLineHeight={contentTitleLineHeight}
          isView={wayAnimation[1]}
        >
          <span>Mutual trust</span>
        </ContentTitle>
        <Inner>
          <Top isView={wayAnimation[1]}>
            <Front>그때 얘기했잖아</Front>
            <Back> 라고 말하지 않아요</Back>
          </Top>
          <Bottom isView={wayAnimation[1]}>
            ‘지난번에 말씀드렸는데.’라는 페이워크에서 말하지 않아요. 상대방에게
            상기시켜야 할 일이 있다면 “아마 지난주 회의에서 나왔던 얘기였는데,
            혹시 이 의견에 어떻게 생각하세요?”라고 다시 한번 의견과 의사를
            물어요.
          </Bottom>
        </Inner>
      </FirstInnerContainer>
      <InnerContainer>
        <ContentTitle
          contentTitleFontSize={contentTitleFontSize}
          contentTitleLineHeight={contentTitleLineHeight}
          isView={wayAnimation[2]}
        >
          <span>
            Constructive
            <br />
            criticism
          </span>
        </ContentTitle>
        <Inner>
          <Top isView={wayAnimation[2]}>
            <Front>건설적인 비판</Front>
            <Back>을 해요</Back>
          </Top>
          <Bottom isView={wayAnimation[2]}>
            비판적으로 바라보는 일은 매우 중요합니다. 하지만 기본적으로 조직과
            동료에게 가치가 있으며 건설적이어야 한다는 점도 잊어서는 안 됩니다.
            상대의 결점을 찾아내어 공격하는 등 비판을 위한 비판이 되지 않도록
            조심해야 합니다. 상대의 감정을 배려하여 “이 숫자는 피부에 와닿지
            않는데요, 어디에서 찾으셨어요?” 처럼 부드럽게 질문합니다.
          </Bottom>
        </Inner>
      </InnerContainer>
      <InnerContainer>
        <ContentTitle
          contentTitleFontSize={contentTitleFontSize}
          contentTitleLineHeight={contentTitleLineHeight}
          isView={wayAnimation[3]}
        >
          Humblism
        </ContentTitle>
        <Inner>
          <Top isView={wayAnimation[3]}>
            <Front>항상 남이 나보다 낫다</Front>
            <Back>고 생각해요</Back>
          </Top>
          <Bottom isView={wayAnimation[3]}>
            어떤 말을 꺼내기 전에, ‘내가 지금 이 말을 하면 상대방은 어떻게
            받아들여질까?’ 그리고 ‘상대방은 지금 대화할 준비가 되어 있나?’ 한 번
            생각하고 말을 꺼내는 습관을 들여요. 늘 ‘상대방이 나보다 더 낫다’라는
            마음으로 본인이 하고픈 말을 다시 한번 정리해보면 더 쉬어요.
          </Bottom>
        </Inner>
      </InnerContainer>
      <InnerContainer>
        <ContentTitle
          contentTitleFontSize={contentTitleFontSize}
          contentTitleLineHeight={contentTitleLineHeight}
          isView={wayAnimation[4]}
        >
          Team play
        </ContentTitle>
        <Inner>
          <Top isView={wayAnimation[4]}>
            <Front>내 옆 사람</Front>
            <Back>을 도와요</Back>
          </Top>
          <Bottom isView={wayAnimation[4]}>
            내 업무를 끝내는 것은 매우 중요해요. 하지만 내 옆 동료가 손이
            부족하다면, 본인이 도울 수 있는 일이라면! 도움이 필요한지 물어봐요.
            내 일만 잘하는 것이 아니라, 동료에게 적절한 도움을 주는 사람이
            페이워크에서 일 잘하는 사람이예요.
          </Bottom>
        </Inner>
      </InnerContainer>
    </Container>
  );
};

export default Way;

const Container = styled.div`
  width: 1200px;
  margin: 150px 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.tablet} {
    margin: 100px 50px 0px;
    /* 좌측정렬 */
    /* display: block; */
  }
  @media ${(props) => props.theme.mobile} {
    width: 335px;
    display: block;
    overflow: hidden;
    margin: 88px 20px 0px;
  }
`;

const Title = styled.div`
  font-size: 42px;
  line-height: 42px;
  font-weight: 600;
  color: ${colors.Body00};
  text-align: center;
  opacity: 0;
  transform: matrix(1, 0, 0, 1, 0, 30);
  ${(props) =>
    props.isView &&
    css`
      opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, 0);
      transition: transform 0.55s 0.2s ease-out, opacity 0.55s 0.2s linear;
    `}
  @media ${(props) => props.theme.tablet} {
    font-size: 34px;
    line-height: 34px;
    /* 좌측정렬 */
    text-align: start;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 24px;
    line-height: 34px;
    /* 좌측정렬 */
    text-align: center;
  }
`;

const FirstInnerContainer = styled.div`
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${(props) => props.theme.tablet} {
    flex-direction: column;
    margin-top: 60px;
  }
  @media ${(props) => props.theme.mobile} {
    /* display: block; */
    margin-top: 40px;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${(props) => props.theme.tablet} {
    flex-direction: column;
    margin-top: 100px;
  }
  @media ${(props) => props.theme.mobile} {
    /* display: block; */
    margin-top: 80px;
  }
`;

const ContentTitle = styled.div`
  /* min-width: 420px; */
  font-size: ${(props) => `${props.contentTitleFontSize}px`};
  line-height: ${(props) => `${props.contentTitleLineHeight}px`};
  font-weight: 600;
  letter-spacing: -0.04em;
  color: ${colors.Body00};
  opacity: 0;
  transform: matrix(1, 0, 0, 1, 30, 0);
  ${(props) =>
    props.isView &&
    css`
      opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, 0);
      transition: transform 0.65s 0s ease-out, opacity 0.65s 0s linear;
    `}

  @media ${(props) => props.theme.tablet} {
    /* font-size: 74px;
    line-height: 74px; */
  }
  /* @media ${(props) => props.theme.mobile} {
    margin: 88px 20px 0px;
    width: auto;
  } */
`;

const Inner = styled.div`
  max-width: calc((100% / 2) - 20px);
  margin-left: 40px;

  @media ${(props) => props.theme.tablet} {
    max-width: 100%;
    margin: 30px 0 0;
  }
  @media ${(props) => props.theme.mobile} {
    max-width: 100%;
    margin: 30px 0 0;
  }
`;

const Top = styled.div`
  opacity: 0;
  ${(props) =>
    props.isView &&
    css`
      opacity: 1;
      transition: transform 0.55s 0s ease-out, opacity 0.55s 0s linear;
    `}
`;

const Front = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: ${colors.Body00};

  @media ${(props) => props.theme.tablet} {
    font-size: 20px;
  }
  /* @media ${(props) => props.theme.mobile} {
    margin: 88px 20px 0px;
    width: auto;
  } */
`;

const Back = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: ${colors.G3};

  @media ${(props) => props.theme.tablet} {
    font-size: 20px;
  }
  /* @media ${(props) => props.theme.mobile} {
    margin: 88px 20px 0px;
    width: auto;
  } */
`;

const Bottom = styled.div`
  margin-top: 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.Body00};
  word-break: keep-all;
  opacity: 0;
  transform: matrix(1, 0, 0, 1, 0, 30);
  ${(props) =>
    props.isView &&
    css`
      opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, 0);
      transition: transform 0.55s 0.2s ease-out, opacity 0.55s 0.2s linear;
    `}
`;
