import React from "react";

// tools
import styled from "styled-components";

// globals
import colors from "../../../globals/colors";

// images
import Team01 from "../../../assets/images/about/img_team01.png";
import Team03 from "../../../assets/images/about/img_team03.png";
import Team04 from "../../../assets/images/about/img_team04.png";
import Team23 from "../../../assets/images/about/img_team23.png";
import Team24 from "../../../assets/images/about/img_team24.png";

import Team15 from "../../../assets/images/about/img_team15.png";
const COWORKERS_ARR = [
  {
    img: Team01,
    name: "손지인",
    position: "CEO",
    commentLine1: "우리가 쫓는 가치가",
    commentLine2: "우리 가치를 결정한다",
  },
  {
    img: Team03,
    name: "클레어",
    position: "COO",
    commentLine1: "#더 나은 내일",
    commentLine2: "#페이워크 #공감",
  },
  {
    img: Team04,
    name: "김호연",
    position: "Backend Developer",
    commentLine1: "특별한걸",
    commentLine2: "당연하게",
  },
  {
    img: Team23,
    name: "최재석",
    position: "Frontend Developer",
    commentLine1: "쉽고",
    commentLine2: "편하게",
  },
  {
    img: Team24,
    name: "이세인",
    position: "Designer",
    commentLine1: "당신의 문제에",
    commentLine2: "사려깊게 공감할 디자이너",
  },
  {
    img: Team15,
    name: "다음은?",
    position: "",
    commentLine1: "",
    commentLine2: "",
  },
];

const Coworkers = ({ innerWidth }) => {
  const sideMargin =
    innerWidth > 1454 ? 120 : 120 - 43 * ((1454 - innerWidth) / (1454 - 744));
  const marginTopTM =
    innerWidth > 744 ? 180 : 180 - 60 * ((744 - innerWidth) / (744 - 375));
  const headerFontTM =
    innerWidth > 1920
      ? 42
      : innerWidth >= 744
      ? 42 - 8 * ((1920 - innerWidth) / (1920 - 744))
      : 34 - 10 * ((743 - innerWidth) / (743 - 375));
  const subHeaderFontTM =
    innerWidth > 1920
      ? 24
      : innerWidth >= 744
      ? 24 - 4 * ((1920 - innerWidth) / (1920 - 744))
      : 20 - 4 * ((743 - innerWidth) / (743 - 375));

  const headerMarginTM =
    innerWidth > 744 ? 16 : 16 + 4 * ((744 - innerWidth) / (744 - 375));

  return (
    <Container sideMargin={sideMargin} marginTopTM={marginTopTM}>
      <Header>
        <MainHeader headerFontTM={headerFontTM}>함께하는 사람들</MainHeader>
        <SubHeader
          subHeaderFontTM={subHeaderFontTM}
          headerMarginTM={headerMarginTM}
        >
          Small Team, but Global Mission
        </SubHeader>
      </Header>
      <Content>
        {COWORKERS_ARR.map((worker, idx) => {
          return (
            <ContentWrapper
              noMargin={(idx + 1) % 4 === 0}
              noMarginThird={(idx + 1) % 3 === 0}
              noMarginSecond={(idx + 1) % 2 === 0}
              key={idx}
              lastCheck={idx + 1 === COWORKERS_ARR.length}
            >
              <WorkerContent>
                <ImgWrapper>
                  <ImgWorker src={worker.img} />
                  <CommentImg lastCheck={idx + 1 === COWORKERS_ARR.length}>
                    <div>{worker.commentLine1}</div>
                    <div>{worker.commentLine2}</div>
                  </CommentImg>
                </ImgWrapper>
                <InfoWorker>
                  <NameInfo>{worker.name}</NameInfo>
                  <PosInfo>{worker.position}</PosInfo>
                </InfoWorker>
              </WorkerContent>
            </ContentWrapper>
          );
        })}
      </Content>
    </Container>
  );
};

export default Coworkers;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props) => `150px ${props.sideMargin}px 0`};
  /* margin: 150px 120px 0; */
  width: 1200px;

  @media ${(props) => props.theme.tablet} {
    margin-top: ${(props) => `${props.marginTopTM}px`};
    /* margin: ${(props) => `${props.marginTopTM}px 50px 0`}; */
  }

  @media ${(props) => props.theme.mobile} {
    margin-top: 120px;

    /* width: 320px; */
    width: auto;
    display: block;
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.Body00};
  margin-bottom: 20px;

  @media ${(props) => props.theme.mobile} {
    margin-bottom: 0px;
  }
`;

const MainHeader = styled.div`
  font-size: ${(props) => `${props.headerFontTM}px`};
  line-height: ${(props) => `${props.headerFontTM}px`};
  font-weight: 600;

  @media ${(props) => props.theme.mobile} {
    font-size: 24px;
    line-height: 24px;
  }
`;
const SubHeader = styled.div`
  font-size: ${(props) => `${props.subHeaderFontTM}px`};
  line-height: ${(props) => `${props.subHeaderFontTM}px`};
  margin-top: 30px;
  font-weight: 600;

  @media ${(props) => props.theme.tablet} {
    margin-top: ${(props) => `${props.headerMarginTM}px`};
  }

  @media ${(props) => props.theme.mobile} {
    font-size: 16px;
    line-height: 16px;
    margin-top: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (max-width: 1454px) {
    justify-content: center;
  }

  @media (max-width: 761px) {
    justify-content: space-between;
  }

  @media (max-width: 743px) {
    justify-content: center;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  /* cursor: pointer; */
  cursor: ${(props) => (props.lastCheck ? "unset" : "pointer")};
  width: 285px;
  height: 240px;
  margin-top: 40px;
  display: flex;
  margin-right: ${(props) => (props.noMargin ? "0px" : "20px")};

  @media (max-width: 1454px) {
    margin-right: ${(props) => (props.noMarginThird ? "0px" : "20px")};
  }

  @media (max-width: 1108px) {
    margin-right: ${(props) => (props.noMarginSecond ? "0px" : "20px")};
  }

  @media (max-width: 761px) {
    margin-right: 0px;
  }

  @media (max-width: 743px) {
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const WorkerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.idxCheck && "60px"};
`;

const ImgWrapper = styled.div`
  width: 285px;
  height: 200px;
  position: relative;
`;

const CommentImg = styled.div`
  ${ImgWrapper}:hover & {
    display: flex;
  }

  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 200px;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  border-radius: 10px;
  background: ${(props) => !props.lastCheck && `rgba(0, 0, 0, 0.6)`};
`;

const ImgWorker = styled.img`
  width: 285px;
  height: 200px;
`;

const InfoWorker = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  height: 20px;
`;

const NameInfo = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const PosInfo = styled.div`
  margin-left: 10px;
  margin-top: 3px;
  font-size: 14px;
`;
