const colorSchemas = {
    mainG: "#28272f",
    mainB: "#0500FF",
  
    mainSubG: "#3B3A41",

    Body00: "#1d1d1f",
    Primary00: "#346DFF",
  
    G1: "#535259",
    G2: "#68686D",
    G3: "#949397",
    G4: "#BFBEC1",
    G5: "#D4D4D5",
    G6: "#E9E9EA",
    G7: "#F3F3F3",
    G8: "#F9F9F9",
    B1: "#1F50FF",
    B2: "#346DFF",
    B3: "#4D7FFF",
    B4: "#699CFF",
    B5: "#85B6FF",
    B6: "#9BC3FF",
    B7: "#BED8FF",
    B8: "#E5EFFF",
    Secondary1: "#FFB342",
    Secondary2: "#5E2EE9",
    Secondary3: "#FF476C",
    Secondary4: "#06F07F",
    Secondary5: "#0085FF",
  };
  
  export default colorSchemas;
  