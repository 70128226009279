import React, { useEffect } from "react";
import MobileDetect from "mobile-detect";
// components
import Header from "../../components/Header";
import Main from "./components/Main";
import Sub from "./components/Sub";
import Popup from "../../components/Popup";

// utils
import { setMetaTags } from "../../utils/metaTag";

// tools
import Amplitude from "amplitude-js";
import Footer from "../../components/Footer";

const Service = () => {
  useEffect(() => {
    if (document.referrer !== "" && document.referrer.includes("app")) {
      let md = new MobileDetect(window.navigator.userAgent);
      if (md.os() === "iOS") {
        window.open("https://itunes.apple.com/app/id1547557352");
      } else {
        window.open(
          "https://play.google.com/store/apps/details?id=io.paywork.mobile"
        );
      }
    }
    setMetaTags({
      title: "견적서 페이워크",
      description:
        "깔끔하고 눈에 확 띄는 견적서, 모바일에서 빠르게 만들고 수정 및 공유하세요!",
    });
    document.body.style.overflow = "unset";
    Amplitude.getInstance().logEvent("22.02_서비스소개_PV");
  }, []);
  return (
    <div style={{ minHeight: "600vh" }}>
      <Header
        backgroundColor="rgba(255, 255, 255, 0.4)"
        backDropFilter="blur(10px)"
        isWhite={true}
      />
      <Main />
      <Sub />
      <Footer />
    </div>
  );
};

export default Service;
