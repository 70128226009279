import React, { useEffect } from "react";
import { Link } from "react-router-dom";

//tool
import styled from "styled-components";

//global
import colors from "../../globals/colors";

//components
import Header from "../../components/Header";

//tools
import Amplitude from "amplitude-js";
import { setMetaTags } from "../../utils/metaTag";

//time
import moment from "moment";
import "moment/locale/ko";

import data from "../../globals/notice";

const Event = () => {
  useEffect(() => {
    document.title = "공지사항 | 페이워크";

    setMetaTags({
      title: "공지사항 | 페이워크",
      description: "",
    });

    Amplitude.getInstance().logEvent("22.02_이벤트_PV");
  }, []);

  const onClickEventPopup = () => {
    // TODO: 링크 변경 필요
    window.open("https://console.paywork.io/event", "_blank");
  };

  return (
    <Container>
      <Inner>
        {data.map((notice, index) => {
          console.log(notice);
          return (
            <NoticeItem key={index}>
              {/* 공지사항 세부페이지 pathname을 list index에서 key값을 기준으로 동작하게 변경 */}
              <Link
                to={{
                  pathname: `/notice-detail/${notice.key}`,
                }}
              >
                <NoticeTitle>{notice.title}</NoticeTitle>
                <NoticeDate>
                  {moment(notice.date).format("YYYY년 M월 D일 dd요일")}
                </NoticeDate>
              </Link>
            </NoticeItem>
          );
        })}
      </Inner>
    </Container>
  );
};

export default Event;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 360px);
  @media ${(props) => props.theme.mobile} {
  }
`;

const Inner = styled.div`
  /* width: 100%; */
  padding: 0 16px;
  display: block;
  flex-direction: row;
  justify-content: center;
  @media ${(props) => props.theme.mobile} {
    display: block;
  }
`;

const Title = styled.div`
  font-size: 42px;
  font-weight: 600;
  color: ${colors.Body00};
  @media ${(props) => props.theme.tablet} {
    font-size: 34px;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 24px;
  }
`;

const InnerContainer = styled.div`
  /* width: 100%; */
  margin-top: 100px;
  @media ${(props) => props.theme.tablet} {
    margin-top: 60px;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 40px;
  }
`;

const NoticeItem = styled.div`
  padding: 20px 0px;
  border-bottom: 1px solid ${colors.G7};
`;

const NoticeTitle = styled.div`
  font-family: "Pretendard";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #28272f;
  margin: 0px 0px 10px 0px;
`;

const NoticeDate = styled.div`
  font-family: "Pretendard";
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #adb5bd;
`;

const RowEvent = styled.div`
  /* width: 100%; */
  margin-bottom: 60px;
  display: flex;
  flex-direction: row;
  .event-end {
    margin-left: 20px;
  }
  .event-with-ongoing {
    cursor: pointer;
    margin-right: 20px;
  }
  @media ${(props) => props.theme.tablet} {
    flex-direction: column;
    margin-bottom: 40px;
    .event-end {
      margin-top: 40px;
      margin-left: 0px;
    }
    .event-with-ongoing {
      margin-right: 0px;
      margin-bottom: 40px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 60px;
    .event-end {
      margin-top: 60px;
      margin-left: 0px;
    }
  }
`;

const EachEvent = styled.div`
  flex: 1;
  .hover-transform {
    transform: scale(1);
    transition: transform 0.55s 0.2s ease-out, opacity 0.55s 0.2s linear;
  }
  &:hover {
    .hover-transform {
      transform: scale(1.05);
      transition: transform 0.55s 0.2s ease-out, opacity 0.55s 0.2s linear;
    }
  }
`;

const ImageBox = styled.div`
  overflow: hidden;
  border-radius: 30px;
`;

const EventImage = styled.img`
  display: block;
  width: 100%;
  @media ${(props) => props.theme.mobile} {
    max-width: 335px;
  }
`;

const EventTitle = styled.div`
  margin-top: 20px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: ${colors.Body00};
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media ${(props) => props.theme.mobile} {
    font-size: 16px;
    line-height: 24px;
    height: 24px;
  }
`;

const EventInfo = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  @media ${(props) => props.theme.mobile} {
    margin-top: 12px;
  }
`;

const EventStatus = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.B2};

  &.black {
    color: ${colors.Body00};
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 12px;
  }
`;

const EventDueDate = styled.div`
  margin-left: 6px;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.G3};
  @media ${(props) => props.theme.mobile} {
    font-size: 12px;
    margin-left: 4px;
  }
`;
