// 공지사항 세부페이지 pathname을 list index에서 key값을 기준으로 동작하게 변경하여서 중복되면 안됨
const notice = [
  {
    title: "2023년 11월 16일부터 요금제가 변경될 예정이에요",
    date: new Date(2023, 9, 13),
    key: "nov-pl-notice",
    isImage: false,
    contents: [
      {
        title: "요금제 정책 변경 예정 안내",
        contents: `안녕하세요.\n돈을 청구하는 것이 쉽고 당연한 일상을 만드는 페이워크입니다.\n
        내부 정책 변경과 페이워크의 새로운 도약을 위해 <highlight>2023년 11월 16일 목요일부터</highlight> 요금제가 변경될 예정입니다.\n
        자세한 사항은 아래 내용을 확인하셔서 서비스 이용에 불편함 없으시길 요청드리며,\n
        더욱 더 좋은 서비스로 보답해 드릴 수 있도록 항상 노력하겠습니다.\n
        감사합니다`,
      },
      {
        title: "변경일자",
        contents: "2023년 11월 16일(목)",
      },
      {
        title: "변경사항",
        contents: "무료•유료 요금제 변경과 추가 구매 요금제 구성",
      },
      {
        title: "요금제 변경 내용",
        substring: (
          <>
            <p>
              <h3>1. 무료 요금제</h3>
              <highlight>2023년 11월 16일</highlight> 이후 무료 요금제는 <highlight>폐지</highlight>되며,<br/>
              기존 페이워크 서비스를 무료로 이용 중이셨던 고객님들 중 사전 <highlight>신용카드 정보를 등록하신</highlight> 고객님들에 한하여 1개월 동안 요금제 무료 이용 후 <highlightRegular>9,900원 유료 요금제로</highlightRegular> <highlight>자동 전환</highlight>됩니다.
            </p>

            <p>
              <h3>2. 유료 요금제</h3>
              <p>
                <b>[기존 9,900원 유료 요금제]</b><br/>
                <span>
                  거래 서류 공유 월 100건(이메일&카카오톡)<br/>
                  세금계산서 월 3건 생성<br/>
                  무제한 거래 서류 생성 및 보관<br/>
                  무제한 사인/도장/명함 생성<br/>
                  무제한 사업자/담당자 생성<br/>
                  품목 일괄 엑셀 업로드
                </span>
              </p>
              <pre style={{fontSize:16}}><b>      ↓      </b></pre>
              <p>
                <b>[변경 9,900원 유료 요금제]</b><br/>
                <span>
                  거래 서류 공유 이메일 월 10건 / 카카오톡 30건<br/>
                  세금계산서 월 5건 생성<br/>
                  무제한 거래 서류 생성 및 보관<br/>
                  무제한 사인/도장/명함 생성<br/>
                  무제한 사업자/담당자 생성<br/>
                  품목 일괄 엑셀 업로드
                </span>
              </p>
            </p>

            <p>
              <h3>3. 추가 요금제</h3>
              <span>기본 제공되는 기능에 대한 건수가 모두 소진될 시 필요 기능별 추가 구매를 할 수 있도록 추가 요금제를 구성할 예정입니다.</span>
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: "이용약관 개정 안내",
    date: new Date(2023, 5, 28),
    key: "0011",
    descriptionTop: "",
    descriptionBottom: "",
    isImage: false,
    contents: [
      {
        title: "이용약관 개정 안내",
        contents: `안녕하세요. 페이워크입니다.\n
        페이워크 서비스를 이용해 주셔서 진심으로 감사드립니다.\n
        이용약관과 개인정보 수집 이용 동의가 추가되어 공지드립니다.`,
        substring: "",
      },
      {
        title: "개정일자",
        contents: "2023년 6월 28일",
        substring: "",
      },
      {
        title: "개정사유",
        contents: "OCR 서비스 추가 관련 약관 및 개인정보 수집 이용 동의 추가",
        substring: "",
      },
      {
        title: "이용약관 개정 내용",
        substring: (
          <>
            <p>
              <b>상세 변경 내용</b>
            </p>
            <p>제 6조 (서비스이용) 10항 추가</p>
            <p>제14조 (개인정보 위탁과 보호) 추가</p>
            <p>개인정보 수집 이용 동의 추가</p>

            <p>자세한 내용은 아래 전문에서 확인하실 수 있습니다.</p>
            <a
              href="https://paywork.notion.site/a0953bd1404e455fa01cff58dd116e07"
              target="_blank"
              style={{ color: "#0052CC" }}
              rel="noreferrer"
            >
              서비스 이용약관
            </a>
            <br />
            <a
              href="https://paywork.notion.site/7dc473bccc594bfe815074026b4b6233"
              target="_blank"
              style={{ color: "#0052CC" }}
              rel="noreferrer"
            >
              개인정보 수집 이용 동의
            </a>
          </>
        ),
      },
    ],
  },
  {
    title: "이용약관 개정 안내",
    date: new Date(2023, 4, 31),
    key: "0010",
    descriptionTop: "",
    descriptionBottom: "",
    isImage: false,
    contents: [
      {
        title: "이용약관 개정 안내",
        contents: `안녕하세요. 페이워크입니다.\n
        페이워크 서비스를 이용해 주셔서 진심으로 감사드립니다.\n
        이용약관이 일부 변경되어 공지드립니다.`,
        substring: "",
      },
      {
        title: "개정일자",
        contents: "2023년 5월 31일",
        substring: "",
      },
      {
        title: "개정사유",
        contents:
          "현금영수증, 세금계산서 관련 서비스 내용 추가, 약관 조항 순서 변경",
        substring: "",
      },
      {
        title: "이용약관 개정 내용",
        substring: (
          <>
            <p>
              <b>상세 변경 내용</b>
            </p>
            <p>
              제 2 조 (용어의 정의) 2항, 3항, 4항 추가 및 변경
              <br />제 6 조 (서비스 이용) 1항 변경, 2항 6호, 7호, 8호 추가, 3항
            </p>
            <p>
              <b>추가 내용</b>
            </p>{" "}
            <p>
              제 7 조 (요금제) → 제 7 조 (서비스 변경) 변경 제 11조 (지급결제
              대행 서비스 이용료) → 제 11 조 (요금제 결제 취소 및 환불) 변경
              <br />
              제 12 조 (정산 금액 출금 요청과 환불) → 제 12 조 (PG 결제수단)
              변경
              <br />
              제 13 조 (지급결제 대행 서비스에 대한 회사의 면책) → 제 13 조
              (사업자 인증과 정산 정보 등록) 변경
              <br />
              제 14 조 (서비스 이용) → 제 14 조 (지급결제 대행 서비스 이용료)
              변경
              <br />
              제 15 조 (서비스 중지) → 제 15 조 (정산 금액 출금 요청과 환불)
              변경
              <br />
              제 16 조 (서비스 이용의 제한) → 제 16 조 (지급결제 대행 서비스에
              대한 회사의 면책) 변경
              <br />
              제 29 조 (면책) → 제 29 조 (발신 통지•거래생성, 변경 및 취소) 변경
              <br />
              제 30 조 (분쟁의 해결) → 제 30 조 (면책) 변경
              <br />
              제 28 조 (발신 통지•거래생성, 변경 및 취소) → 제 28 조 (회원탈퇴)
              변경
              <br />
              제 32 조 (책임 제한) 추가
              <br />
              <br />
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: "이용약관 개정 안내",
    date: new Date(2023, 5 - 1, 14),
    key: "0009",
    descriptionTop: "",
    descriptionBottom: "",
    isImage: false,
    contents: [
      {
        title: "이용약관 개정 안내",
        contents: `안녕하세요. 페이워크입니다.\n
        페이워크 서비스를 이용해 주셔서 진심으로 감사드립니다.\n
        이용약관이 일부 변경되어 공지드립니다.`,
        substring: "",
      },
      {
        title: "개정일자",
        contents: "2023년 5월 14일",
        substring: "",
      },
      {
        title: "개정사유",
        contents: "신규 기능 업데이트, 신규 요금제 추가로 인한 약관 내용 변경",
        substring: "",
      },
      {
        title: "이용약관 개정 내용",
        substring: (
          <>
            <p>
              <b>제 6조 (서비스 내용)</b>
            </p>
            <p>
              회원이 견적서, 거래명세서, 영수증, 청구서 등의 거래문서를
              모바일에서 작성할 수 있는 기능 제공 →{" "}
              <b>
                (변경) 회원이 견적서, 거래명세서, 영수증, 청구서, 발주서,
                현금영수증, 세금계산서 등의 거래문서를 모바일과 웹에서 작성할 수
                있는 기능 제공
              </b>
            </p>
            <p>
              <b>제 7 조 (요금제)</b>
            </p>{" "}
            <p>
              1.회원의 사이트 및 앱 가입은 최초 가입은 무료이며, 요금제 결제 후
              각 요금제에 속한 기능을 이용할 수 있습니다. 2022년 8월 17일 기준
              요금제는 다음과 같습니다. 무료, 1인 요금제(9,900원), 2인 이상
              요금제(출시 예정) →{" "}
              <b>
                (변경) 회원의 사이트 및 앱 가입은 최초 가입은 무료이며, 요금제
                결제 후 각 요금제에 속한 기능을 이용할 수 있습니다. 2022년 4월
                24일 기준 요금제는 다음과 같습니다. 무료, 1인 요금제(9,900원),
                5인 이상 요금제(44,000원 출시 예정), 10인 이상 요금제(99,000원
                출시예정)
              </b>
            </p>{" "}
            <p>
              2. 9,900원 요금제에서 2인 이상 요금제로 상향이 가능합니다. 이때,
              결제주기일은 변경한 요금제를 첫 결제한 일자로 변경됩니다. →{" "}
              <b>(변경)</b>{" "}
              <b>
                9,900원 요금제에서 5인 이상 요금제로 상향이 가능합니다. 이때,
                결제주기일은 변경한 요금제를 첫 결제한 일자로 변경됩니다.제 10조
                (사업자 인증과 정산 정보 등록)
              </b>
            </p>{" "}
            <p>
              3. 사업자 인증의 경우 회원의 정보 등록 후 회사에서 별도의 승인
              절차를 진행합니다. 승인이 반려될 경우 회원은 이를 즉각 확인하고
              반려 사유에 따라 수정을 진행하여야 합니다.
              <br /> →
              <b>
                (변경) 사업자 인증을 위하여 회사는 회원에게 사업자등록증에
                기재된 정보, 계좌정보(예금주이름,계좌번호), 그 밖의 인증 서류를
                요청할 수 있습니다. 승인이 반려될 경우 회원은 이를 즉각 확인하고
                반려 사유에 따라 수정을 진행하여야 합니다.
              </b>
              <br />
            </p>
            <a
              href="https://www.notion.so/paywork/a0953bd1404e455fa01cff58dd116e07"
              target="_blank"
              style={{ color: "#0052CC" }}
            >
              서비스 이용약관
            </a>
          </>
        ),
      },
    ],
  },
  {
    title: "이용약관 개정 안내",
    date: new Date(2023, 3, 20),
    key: "0008",
    descriptionTop: "",
    descriptionBottom: "",
    isImage: false,
    contents: [
      {
        title: "이용약관 개정 안내",
        contents: `안녕하세요. 페이워크입니다.\n
          페이워크 서비스를 이용해 주셔서 진심으로 감사드립니다.\n
          요금제가 변경됨에 따라 이용약관이 개정됨을 안내드리오니, 이용에 참고해주시기 바랍니다.`,
        substring: "",
      },
      {
        title: "변경시기",
        contents: "변경된 이용약관은 2023년 4월 24일자로 효력이 발생됩니다.",
        substring: "",
      },
      {
        title: "이용약관 개정 내용 ",
        contents: `• 이용자 명칭을 사용자로 면경\n• 3,300원 요금제 내용 삭제 `,
        substring: (
          <>
            {`자세한 내용은 아래 이용약관 전문에서 확인하실 수 있습니다.\n`}
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "30px",
                marginTop: 10,
              }}
            >
              <a
                href="https://www.notion.so/paywork/2022-9-28-9323e4ea4874491a90e3a39e153b538a"
                target="_blank"
                style={{ color: "#0052CC" }}
              >
                개정 전 이용약관
              </a>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", height: "30px" }}
            >
              <a
                href="https://www.notion.so/paywork/a0953bd1404e455fa01cff58dd116e07"
                target="_blank"
                style={{ color: "#0052CC" }}
              >
                개정 후 이용약관
              </a>
            </div>
          </>
        ),
      },
    ],
  },
  {
    title: "2023년 4월 24일 이후 요금제가 변경될 예정이에요",
    date: new Date(2023, 3, 11),
    key: "0007",
    descriptionTop: "",
    descriptionBottom: "",
    isImage: true,
    contents: [
      // {
      //   title: "1. 현금영수증 발급 기능을 출시했어요",
      //   contents:
      //     "공동인증서 등록 없이 현금영수증 발급, 취소를 할 수 있고 발급 후 공유기능도 같이 사용할 수 있어요",
      //   substring: "공유톡, 이메일, 링크, PDF, 이미지로 공유할 수 있어요",
      // },
      // {
      //   title: "2. 홈 화면이 개편됐어요",
      //   contents:
      //     "필요한 서류를 더 빠르게 만들 수 있도록 서류를 용도에 따라 구분했어요",
      //   substring:
      //     "거래용, 세금 신고용으로 확인 가능하며 세금계산서는 출시 예정이에요",
      // },
      // {
      //   title: "3. 거래 문서함이 개편됐어요",
      //   contents:
      //     "거래 문서함 이름이 서류 보관함으로 변경됐어요. 만든 서류를 빨리 찾아볼 수 있도록 서류함 폴더를 만들고 최근 만든 서류도 바로 볼 수 있게 개편됐어요",
      //   substring: "",
      // },
    ],
  },
];

export default notice;
