const size = {
  mobile: "375px",
  tablet: "744px",
  desktop: "1199px",
};

// eslint-disable-line no-unused-vars
const theme = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  desktop: `(max-width: ${size.desktop})`,
};

export default theme;
