import React, { useEffect, useState } from "react";

import { Typewriter } from "react-simple-typewriter";
// images
import PhoneImage from "../assets/update/service_phone_240424.png";
import DownloadBackground from "../assets/section0_bg.png";
import DownloadImage from "../assets/section0_img.png";
import DownloadTaImage from "../assets/section0_img_ta.png";
import DownloadMoImage from "../assets/section0_img_mo.png";
import AppleIcon from "../assets/btn_apple.png";
import GoogleIcon from "../assets/btn_google.png";
// utils
import { setMetaTags } from "../../../utils/metaTag";
// tools
import styled, { keyframes, css } from "styled-components";
import Amplitude from "amplitude-js";

const Main = () => {
  const clientHeight = document.documentElement.clientHeight;

  const [yOffset, setYOffset] = useState(document.documentElement.scrollTop);
  const [opacity, setOpacity] = useState(0);
  const [transform, setTransform] = useState(60);
  const [scale, setScale] = useState(3);

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.onbeforeunload = function pushRefresh() {
      window.scrollTo(0, 0);
    };

    setMetaTags({
      title: "견적서 페이워크",
      description:
        "깔끔하고 눈에 확 띄는 견적서, 모바일에서 빠르게 만들고 수정 및 공유하세요!",
    });

    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onChangeYOffset);
  }, []);

  useEffect(() => {
    setOpacity(1 - yOffset / (clientHeight * 2));
    setTransform(60 - yOffset / (clientHeight / 12));

    if (innerWidth > 500) {
      if (yOffset >= 2200) {
        setScale(3 - yOffset / (clientHeight * 2));
      } else if (yOffset < 2200) {
        setScale(3);
      }
    } else {
      if (yOffset >= 1200) {
        setScale(3 - yOffset / (clientHeight * 2));
      } else if (yOffset < 1200) {
        setScale(3);
      }
    }
  }, [yOffset]);

  const onChangeYOffset = () => {
    setYOffset(window.pageYOffset);
  };

  const onClickAppStoreButton = () => {
    Amplitude.getInstance().logEvent("download", {
      OS: "ios",
      position: "top",
    });

    window.open("https://itunes.apple.com/app/id1547557352", "_blank");
  };

  const onClickPlayStoreButton = () => {
    Amplitude.getInstance().logEvent("download", {
      OS: "android",
      position: "top",
    });

    window.open(
      "https://play.google.com/store/apps/details?id=io.paywork.mobile",
      "_blank"
    );
  };

  const phrases = ["견적서", "거래명세서", "영수증", "청구서"];

  return (
    <Container>
      <DownloadWrapper yOffset={yOffset}>
        {/* <NoteDownloadWrapper>
          <img src={NoteImg} alt="" />
        </NoteDownloadWrapper>
        <WatchDownloadWrapper>
          <img src={WatchImg} alt="" />
        </WatchDownloadWrapper>
        <ListDownloadWrapper>
          <img src={ListImg} alt="" />
        </ListDownloadWrapper>
        <CoinDownloadWrapper>
          <img src={CoinImg} alt="" />
        </CoinDownloadWrapper> */}

        <DownloadWrapperImage isAnimation={yOffset === 0 || yOffset <= 600} />
        {/* <TitleCover> */}
        {/* <SlideShowCover>
            <Slideshow2 />
          </SlideShowCover> */}
        <TitleDownload>
          <b>
            <Typewriter
              words={phrases}
              loop={true}
              cursor={false}
              pauseTime={1000}
              typeSpeed={100}
              deleteSpeed={0}
            />
          </b>
          의 모든것
          <br />
          페이워크에서 쉽고 간편하게
        </TitleDownload>
        {/* </TitleCover> */}

        <ButtonDownload>
          <AppleButton onClick={onClickAppStoreButton}>
            <img src={AppleIcon} alt="" />
            App Store 다운로드
          </AppleButton>
          <GoogleButton onClick={onClickPlayStoreButton}>
            <img src={GoogleIcon} alt="" />
            Google Play 다운로드
          </GoogleButton>
        </ButtonDownload>
      </DownloadWrapper>

      {/* <TextContent opacity={opacity} yOffset={yOffset}>
        <p>
          계약 성사는
          <br />
          깔끔한 견적서로부터
        </p>
      </TextContent> */}
      <ImageContent scale={scale} transform={transform} yOffset={yOffset}>
        <img src={PhoneImage} alt="" />
      </ImageContent>
    </Container>

    // <Container>
    //   <TextContent opacity={opacity} yOffset={yOffset}>
    //     <p>
    //       끝없는 수정 요청을&nbsp;
    //       <span className="tab-span"><br /></span>
    //       막을 수 없다면<br />
    //       한 만큼 받는 게&nbsp;
    //       <span className="tab-span"><br /></span>
    //       맞지 않나요?
    //     </p>
    //   </TextContent>
    //   <ImageContent scale={scale} transform={transform} yOffset={yOffset}>
    //     <img src={PhoneImage} alt="" />
    //   </ImageContent>
    // </Container>
  );
};

export default Main;

const Container = styled.div`
  padding-top: 68px;
  height: 600vh;
  overflow-x: hidden;

  @media (max-width: 375px) {
    padding-top: 0px;
  }
`;

const FadeIn = () => keyframes`
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
    transition: all ease-out 2s;
  }
`;

const DownloadWrapper = styled.div`
  width: 100%;
  height: 1612px;
  position: relative;

  display: ${(props) => (props.yOffset > 600 ? "none" : "block")};

  background-image: url(${DownloadBackground});
  background-size: 100%;
  background-repeat: no-repeat;
  /* background-color: red; */

  @media ${(props) => props.theme.tablet} {
    width: 100%;
    margin: 0;
  }
`;

const DownloadWrapperImage = styled.div`
  position: absolute;
  top: 61px;
  width: 100%;

  /* padding: 0 120px;
  max-width: 1200px;
  margin: 0 auto; */

  /* max-width: 1440px; */

  height: 2000px;
  background-image: url(${DownloadImage});
  background-size: 60% auto;
  background-repeat: no-repeat;
  background-position: top;

  /* animation: ${FadeIn} 2s; */
  /* ${(props) =>
    css`
      animation: ${FadeIn} 2s;
    `}; */

  @media (max-width: 1920px) {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: initial;
  }

  @media (max-width: 1220px) {
    top: 150px;
  }

  @media (max-width: 1020px) {
    top: 220px;
  }

  @media (max-width: 900px) {
    top: 260px;
  }

  @media ${(props) => props.theme.tablet} {
    background-image: url(${DownloadTaImage});
    top: 157px;
  }

  @media ${(props) => props.theme.mobile} {
    background-image: url(${DownloadMoImage});
    top: 57px;
  }
`;

const NoteDownloadWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: calc(50% - (239px / 2));
  display: flex;

  animation: ${FadeIn} 2s;

  img {
    /* width: 335px;
    height: 451px; */
    width: 18%;
    height: 45%;
  }

  @media ${(props) => props.theme.tablet} {
    img {
      width: 196px;
      height: 259px;
    }
  }
`;

const WatchDownloadWrapper = styled.div`
  animation: ${FadeIn} 2s;

  img {
    /* width: 322px;
    height: 351px; */
    width: 16%;
    height: 34%;

    position: absolute;
    left: 9%;
    top: 34%;
    display: flex;
  }
`;

const ListDownloadWrapper = styled.div`
  animation: ${FadeIn} 2s;

  img {
    /* width: 479px;
    height: 479px; */
    position: absolute;
    right: 15%;
    top: 34%;
    display: flex;

    width: 20%;
    height: 40%;
  }
`;

const CoinDownloadWrapper = styled.div`
  animation: ${FadeIn} 2s;

  img {
    /* width: 181px;
    height: 178px; */

    width: 9%;
    height: 17%;

    position: absolute;
    right: 24%;
    top: 6%;
    display: flex;
  }
`;

const TitleDownload = styled.div`
  text-align: center;
  font-size: 72px;
  line-height: 100px;
  font-weight: 700;
  padding-top: 210px;
  /* justify-content: center;
  flex-direction: row; */

  /* span {
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-right: 300px;
  } */

  b {
    color: #346dff;
  }

  @media ${(props) => props.theme.tablet} {
    padding-top: 314px;
    font-size: 44px;
    line-height: 80px;
  }

  @media (max-width: 500px) {
    padding-top: 190px;
    font-size: 34px;
    line-height: 48px;
  }

  @media ${(props) => props.theme.mobile} {
    padding-top: 160px;
    font-size: 30px;
    line-height: 48px;
  }
`;

const ButtonDownload = styled.div`
  display: flex;
  margin-top: 102px;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
`;

const TitleCover = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
`;

const SlideShowCover = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  max-width: 350px;
  margin-top: 180px;
  z-index: 9999;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
`;

const AppleButton = styled.div`
  width: 222px;
  height: 63px;
  background: #1d1d1f;
  opacity: 0.8;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 20px;
    height: 24.79px;
    margin-right: 10px;
  }
`;

const GoogleButton = styled.div`
  width: 222px;
  height: 63px;
  background: #1d1d1f;
  opacity: 0.8;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 0px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 20px;
    height: 22.28px;
    margin-right: 10px;
  }

  @media (max-width: 500px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`;

const ImageContent = styled.div`
  /* display: ${(props) => (props.yOffset > 8000 ? "none" : "flex")}; */
  display: ${(props) => (props.yOffset > 8000 ? "none" : "flex")};
  position: fixed;
  width: 100%;
  justify-content: center;
  height: 100vh;
  will-change: transform;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;

  img {
    max-width: 100vw;
    width: auto;
    height: 80%;
    transform: ${(props) =>
      props.scale < 1
        ? "scale(1) translateY(0)"
        : `scale(${props.scale}) translateY(${props.transform}%)`};

    will-change: transform;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
  }
  @media ${(props) => props.theme.mobile} {
    /* display: ${(props) => (props.yOffset > 6000 ? "none" : "flex")}; */
    display: ${(props) => (props.yOffset > 6000 ? "none" : "flex")};
  }
`;
const TextContent = styled.div`
  /* position: fixed;
  top: 0; */
  width: 100%;
  /* height: 100vh; */

  display: ${(props) => (props.yOffset > 4000 ? "none" : "flex")};
  align-items: center;
  justify-content: center;

  p {
    //padding: 0 120px;
    margin: 0;
    width: 100%;
    font-size: 74px;
    font-weight: bold;
    line-height: 100px;
    text-align: center;
    opacity: ${(props) => props.opacity};
  }
  span {
    display: none;
  }

  @media (max-width: 1250px) {
    p {
      font-size: 5vw;
      line-height: 1.5;
    }
  }

  @media ${(props) => props.theme.tablet} {
    p {
      padding: 0 50px;
      font-size: 54px;
      line-height: 80px;
    }
    .tab-span {
      display: inline;
    }
  }
  @media (max-width: 600px) {
    p {
      font-size: 8vw;
      line-height: 1.5;
    }
  }
  @media ${(props) => props.theme.mobile} {
    p {
      padding: 0 20px;
      font-size: 40px;
      line-height: 50px;
    }
    .tab-span {
      display: inline;
    }
  }
`;
