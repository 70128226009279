// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import slideshowimg1 from "../assets/update/slideshow1_240424.png";
import slideshowimg2 from "../assets/update/slideshow2_240424.png";
import slideshowimg3 from "../assets/update/slideshow3_240424.png";

import "./styles.css";

const Slideshow = () => {
  SwiperCore.use([Autoplay, Pagination]);
  return (
    <Swiper
      spaceBetween={1000}
      pagination={{ clickable: true }}
      slidesPerView={1}
      centeredSlides={true}
      centeredSlidesBounds={true}
      loop={true}
      loopAdditionalSlides={30}
      autoplay={{ disableOnInteraction: false, delay: 3000 }}
      className="swiper-container-bottom"
    >
      <SwiperSlide>
        <div className="imagecontainer">
          <img src={slideshowimg1} alt="" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="imagecontainer">
          <img src={slideshowimg2} alt="" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="imagecontainer">
          <img src={slideshowimg3} alt="" />
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default Slideshow;
