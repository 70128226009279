import React, { useEffect, useState } from "react";

// images
import CheckboxImage from "../assets/images/popup/checkbox.png";
import CheckboxOnImage from "../assets/images/popup/checkbox_on.png";
import CloseImage from "../assets/images/popup/close.png";
import PopupImage from "../assets/images/popup/popup_pc.png";
import PopupMobileImage from "../assets/images/popup/popup_mobile.png";

// tool
import styled from "styled-components";

const Popup = () => {
  const HAS_VISITED_BEFORE = localStorage.getItem("hasVisitedBefore");
  const [isModal, setIsModal] = useState(HAS_VISITED_BEFORE);
  const [isVisit, setIsVisit] = useState(false);
  const [isSelect, setIsSelect] = useState(false);

  useEffect(() => {
    const handleShowModal = () => {
      if (HAS_VISITED_BEFORE && HAS_VISITED_BEFORE > new Date()) {
        setIsModal(false);
      }

      if (!HAS_VISITED_BEFORE || HAS_VISITED_BEFORE <= new Date()) {
        setIsModal(true);
        document.body.style.overflow = "hidden";
      }
    };

    handleShowModal();
  }, [HAS_VISITED_BEFORE]);

  const handleClose = () => {
    setIsModal(false);

    if (isSelect) {
      setIsVisit(true);
    } else {
      setIsVisit(false);
    }
  };
  useEffect(() => {
    if (isVisit) {
      setIsModal(false);
      let expires = new Date();
      expires = expires.setHours(expires.getHours() + 24);
      localStorage.setItem("hasVisitedBefore", expires);
    }
  }, [isVisit]);

  const onChangeSelectBox = () => {
    setIsSelect(!isSelect);
  };

  const onClickButton = () => {
    //TODO: 주소 변경 필요
    window.open(
      "https://console.paywork.io/event",
      "_blank"
    );
  }

  return (
    <>
      {isModal && (
        <ModalBackgroundContainer>
          <Container>
            <img 
              onClick={onClickButton}
              className="pc-popup"
              src={PopupImage}
              alt="" 
            />
            <img 
              onClick={onClickButton}
              className="mobile-popup"
              src={PopupMobileImage}
              alt="" 
            />

            <CloseButtonContainer>
              <TodayButtonContainer onClick={onChangeSelectBox}>
                <CheckBoxButton
                  bgImage={isSelect ? CheckboxOnImage : CheckboxImage}
                />
                <TextContent>
                  오늘 하루동안 안보기
                </TextContent>
              </TodayButtonContainer>
              <CloseButton bgImage={CloseImage} onClick={handleClose} />
            </CloseButtonContainer>
          </Container>
        </ModalBackgroundContainer>
      )}
    </>
  );
};

export default Popup;

const ModalBackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 998;
  overflow-y: hidden;

  button {
    border: 0;
    background-color: rgba(0, 0, 0, 0);
  }
`;
const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 550px;
  z-index: 999;

  img {
    cursor: pointer;
  }
  
  .pc-popup {
    width: 550px;
  }
  .mobile-popup {
    display: none;
  }
  @media ${(props) => props.theme.tablet} {
    width: 343px;
    height: 550px;

    .pc-popup {
      display: none;
    }
    .mobile-popup {
      display: block;
      width: 343px;
    }
  }
`;
const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding: 0 20px;
  width: 550px;
  height: 40px;
  background-color: #000;

  @media ${(props) => props.theme.tablet} {
    width: 343px;
  }
`;
const TodayButtonContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const CheckBoxButton = styled.button`
  width: 24px;
  height: 24px;
  background-image: ${props => `url(${props.bgImage})`};
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
`;
const TextContent = styled.div`
  margin-left: 4px;
  padding-top: 2px;
  color: #fff;
  font-size: 16px;
`;
const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  background-image: ${props => `url(${props.bgImage})`};
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;