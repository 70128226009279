import React, { useEffect, useState } from "react";

// globals
import colors from "../../../globals/colors";
// images
import FirstSectionImage1 from "../assets/update/section1_image1_new.png";
import FirstSectionImage2 from "../assets/update/section1_image2_new.png";
import FirstSectionImage1mobile from "../assets/update/section1_image1_mobile.png";
import FirstSectionImage2mobile from "../assets/update/section1_image2_mobile.png";
import ScrollSectionImage1 from "../assets/update/section2_image1_new.png";
import ScrollSectionImage2 from "../assets/update/section2_image2_240424.png";
import ScrollSectionImage3 from "../assets/update/section2_image3_new.png";
import ScrollSectionImage4 from "../assets/update/section2_image4_new.png";
import ScrollSectionImage5 from "../assets/update/section2_image5_240424.png";
import ScrollSectionImage6 from "../assets/update/section2_image6_new.png";
import LastSectionImage1 from "../assets/update/section3_image1_mobile_240424.png";
import LastSectionImage2 from "../assets/update/section3_image2_mobile_240424.png";
import LastSectionImage1mobile from "../assets/update/section3_image1_mobile_240424.png";
import LastSectionImage2mobile from "../assets/update/section3_image2_mobile_240424.png";
import ServiceInfoImage from "../assets/service_info_bg.png";

import WebInfoImage from "../assets/update/Webinfoscreen_240424.png";

import Slideshow from "./Slideshow";
// utils
import { setMetaTags } from "../../../utils/metaTag";

// tools
import styled from "styled-components";
import Amplitude from "amplitude-js";

const Sub = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const onClickButton = () => {
    window.wcs.event("main", "console");
    window.open("https://app.paywork.io/signin", "_blank");
    Amplitude.getInstance().logEvent(
      "22.02_서비스소개_지금바로견적서쓰러가기_클릭"
    );
  };

  useEffect(() => {
    window.addEventListener("resize", onChangeInnerWidth);
    setMetaTags({
      title: "견적서 페이워크",
      description:
        "깔끔하고 눈에 확 띄는 견적서, 모바일에서 빠르게 만들고 수정 및 공유하세요!",
    });
    return () => {
      window.removeEventListener("resize", onChangeInnerWidth);
    };
  }, []);

  const onChangeInnerWidth = () => {
    setInnerWidth(window.innerWidth);
  };
  return (
    <Container>
      <TextContent>
        {/* <b>
          쓰세요, 견적서
          <br />
          페이워크에서
        </b>
        <span>
          상대방이 견적서를 승낙하면 계약서와 같아요
          <br />내 권리는 내가 먼저 챙겨요
        </span> */}
        <b>
          언제 어디서나 바로 보내고
          <br />
          신용카드 결제, 미수금 알림까지 한번에
          {/* <br /> */}
          {/* 이런 거래 문서 관리 솔루션 써보셨나요? */}
        </b>
      </TextContent>
      <SectionContainer>
        <Section innerWidth={innerWidth}>
          <SectionTextContent innerWidth={innerWidth}>
            <b>
              <span>채워요</span> 빈칸만
            </b>
            <p>
              <span>받는 분과 항목만 작성하세요</span>
              <br />
              할인, 부가세 계산은 페이워크가 해요
            </p>
          </SectionTextContent>
          <SectionImageContent isBanner={true}>
            {innerWidth > 1000 && <img src={FirstSectionImage1} alt="" />}
            {innerWidth < 1000 && <img src={FirstSectionImage1mobile} alt="" />}
            {innerWidth > 1000 && <img src={FirstSectionImage2} alt="" />}
            {innerWidth < 1000 && <img src={FirstSectionImage2mobile} alt="" />}

            <div className="image-content">
              {/* <SectionTextContent> */}
              언제 어디서나 간편하게 작성 완료!
              {/* </SectionTextContent> */}
            </div>
          </SectionImageContent>
        </Section>
      </SectionContainer>

      <ScrollSectionContainer>
        <Section innerWidth={innerWidth}>
          <SectionTextContent
            className="text-center-content"
            innerWidth={innerWidth}
          >
            <b>
              <span>편해요</span>공유도
            </b>
            <p>
              <span>카카오톡, 이메일, PDF 파일, 이미지, 링크</span>
              <br />
              편한 방법으로 견적서를
              <span className="enter-span">
                <br />
              </span>
              &nbsp;공유할 수 있어요
            </p>
          </SectionTextContent>
          <ScrollRow isMargin={true}>
            <ScrollContent>
              <SectionTextContent innerWidth={innerWidth} isScroll={true}>
                <ScrollTextColor innerWidth={innerWidth}>
                  {""}
                  공유하기 1{" "}
                </ScrollTextColor>

                <b>견적서 등의 거래문서 {"\n"}공유톡 보내기</b>
                <p>
                  <span>빠르게 보내고 싶을땐</span>
                  <br />
                  카카오톡으로 보내요
                </p>
              </SectionTextContent>
            </ScrollContent>

            <ScrollContent>
              <img src={ScrollSectionImage1} alt="" />
            </ScrollContent>
          </ScrollRow>

          <ScrollRow>
            <ScrollContent>
              <img src={ScrollSectionImage2} alt="" />
            </ScrollContent>
            <ScrollContent />
          </ScrollRow>

          <ScrollRow>
            <ScrollContent>
              <SectionTextContent innerWidth={innerWidth} isScroll={true}>
                <ScrollTextColor innerWidth={innerWidth}>
                  공유하기 2{" "}
                </ScrollTextColor>

                <b>이메일로 보내기</b>
                <p>
                  <span>메세지를 함께 보내고 싶을땐</span>
                  <br />
                  이메일로 보내요
                </p>
              </SectionTextContent>
            </ScrollContent>
            <ScrollContent>
              <img src={ScrollSectionImage3} alt="" />
            </ScrollContent>
          </ScrollRow>

          <ScrollRow>
            <ScrollContent>
              <img src={ScrollSectionImage4} alt="" />
            </ScrollContent>
            <ScrollContent />
          </ScrollRow>

          <ScrollRow>
            <ScrollContent>
              <SectionTextContent
                innerWidth={innerWidth}
                isEnd={true}
                isScroll={true}
              >
                <ScrollTextColor innerWidth={innerWidth}>
                  공유하기 3{" "}
                </ScrollTextColor>

                <b>이미지/PDF 저장, 링크로 보내기</b>
                <p>
                  <span>정보 보안이 필요할 땐</span>
                  <br />
                  파일이나 링크로 보내요
                </p>
              </SectionTextContent>
            </ScrollContent>
            <ScrollContent />
          </ScrollRow>

          <ScrollRow>
            <ScrollContent>
              <img src={ScrollSectionImage5} alt="" />
            </ScrollContent>
            <ScrollContent>
              <img src={ScrollSectionImage6} alt="" />
            </ScrollContent>
          </ScrollRow>
        </Section>
      </ScrollSectionContainer>

      <SectionContainer>
        <Section innerWidth={innerWidth}>
          <SectionTextContent
            className="text-center-content"
            innerWidth={innerWidth}
          >
            <b>
              <span>깔끔해요</span> 디자인도
            </b>

            <p>
              <span> 원하는 디자인을 선택해서</span>
              <br />
              견적서를 작성할 수 있어요
            </p>
          </SectionTextContent>
        </Section>
      </SectionContainer>
      <Slideshow />

      <SectionContainer>
        <Section innerWidth={innerWidth}>
          <SectionTextContent
            className="text-center-content"
            innerWidth={innerWidth}
          >
            <b>
              <span>괜찮아요</span> 일만해도
            </b>
            <p>
              <span>
                변동사항 기록은 물론,&nbsp;
                <span className="enter-span">
                  <br />
                </span>
                정산 알림까지
              </span>
              <br />
              페이워크가 기억하고 있을게요
            </p>
          </SectionTextContent>
          <SectionImageContent>
            {innerWidth > 1000 && <img src={LastSectionImage1} alt="" />}
            {innerWidth < 1000 && <img src={LastSectionImage1mobile} alt="" />}
            {innerWidth > 1000 && <img src={LastSectionImage2} alt="" />}
            {innerWidth < 1000 && <img src={LastSectionImage2mobile} alt="" />}
          </SectionImageContent>
        </Section>
      </SectionContainer>

      <SectionContainer isWeb={true}>
        <Section innerWidth={innerWidth}>
          <SectionTextContent
            className="text-center-content"
            innerWidth={innerWidth}
            isWeb={true}
          >
            <b>
              가능해요 <span>웹으로도</span>
            </b>
            <p>
              앱으로 작성한 견적서를
              <br />
              이어서 웹에서 작성할 수 있어요
            </p>
          </SectionTextContent>
        </Section>

        <WebImageContent>
          <img src={WebInfoImage} alt="" />
        </WebImageContent>
      </SectionContainer>

      <InfoContainer>
        <p>
          그럼 지금 바로
          <br />
          견적서 써볼까요?
        </p>
        <button onClick={onClickButton}>지금 바로 쓰러 가기</button>
      </InfoContainer>
    </Container>
  );
};
export default Sub;

const Container = styled.div`
  position: sticky;
  background-color: #fff;
  z-index: 990;
`;
const TextContent = styled.div`
  padding: 150px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${colors.Body00};
  text-align: center;

  b {
    margin-bottom: 30px;
    font-size: 74px;
    line-height: 100px;
    word-break: keep-all;
    white-space: pre-wrap;
  }
  span {
    font-size: 24px;
    line-height: 34px;
  }

  @media ${(props) => props.theme.tablet} {
    padding: 100px 0 0;

    b {
      margin-bottom: 20px;
      font-size: 44px;
      line-height: 60px;
    }
    span {
      font-size: 20px;
      line-height: 30px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding: 80px 0 0;

    b {
      margin-bottom: 20px;
      font-size: 35px;
      line-height: 50px;
    }
    span {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
const SectionContainer = styled.div`
  padding: 0 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: ${(props) => (props.isWeb ? "vw" : "1200px")};
  background-color: ${(props) => (props.isWeb ? "#346DFF" : "")};
  margin-top: ${(props) => (props.isWeb ? "100px" : "0px")};

  @media ${(props) => props.theme.tablet} {
    padding: 0 20px;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 0 20px;
  }
`;

const Section = styled.div`
  padding-top: ${(props) => (props.innerWidth > 1000 ? "120px" : "5vw")};
  width: 100%;

  align-items: ${(props) => (props.isSlide ? "center" : "")};

  @media ${(props) => props.theme.tablet} {
    padding-top: ${(props) => (props.innerWidth > 400 ? "80px" : "70px")};
  }
  @media ${(props) => props.theme.mobile} {
    padding-top: 60px;
  }
`;

const SectionTextContent = styled.div`
  margin-bottom: ${(props) => (props.isEnd ? "2vw" : "0px")};
  padding-left: ${(props) => (props.isScroll ? "25px" : "0px")};
  b {
    display: flex;
    align-items: center;
    min-height: 82px;
    color: ${(props) => (props.isWeb ? "#fff" : "#1d1d1f")};
    font-size: ${(props) => (props.innerWidth > 1000 ? "34px" : "3vw")};
    margin-left: ${(props) => (props.isWeb ? "5px" : "")};

    span {
      margin-right: ${(props) => (props.isWeb ? "" : "10px")};
      margin-left: ${(props) => (props.isWeb ? "8px" : "")};
      color: ${colors.Primary00};
      background-color: #ebf3ff;
      border-radius: 40px;
      padding: 16px;
    }
  }

  p {
    margin: 20px 0 0;
    color: ${(props) => (props.isWeb ? "#fff" : "#68686D")};
    font-weight: ${(props) => (props.isWeb ? "bold" : "bold")};
    font-size: ${(props) => (props.innerWidth > 1000 ? "24px" : "2vw")};
    line-height: 1.5;

    span {
      padding: 0;
      font-weight: "700";
      color: ${colors.Body00};
    }
  }
  .enter-span {
    display: none;
  }
  &.text-end-content {
    b {
      justify-content: flex-end;
    }
    p {
      text-align: right;
    }
  }
  &.sticky-text {
    position: sticky;
    top: 218px;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .enter-span {
      display: none;
    }
  }
  &.text-center-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
      text-align: center;
    }
  }

  @media (max-width: 1300px) {
    &.sticky-text {
      .enter-span {
        display: block;
        width: 0;
        height: 0;
      }
    }
  }

  @media ${(props) => props.theme.tablet} {
    b {
      min-height: 70px;
      font-size: 34px;
    }
    span {
      margin-right: 8px;
      padding: 18px;
    }

    p {
      margin: 20px 0 0;
      color: ${(props) => (props.isWeb ? "#fff" : "#68686D")};

      font-size: 20px;
      line-height: 30px;

      span {
        color: ${colors.Body00};
      }
    }

    &.sticky-text {
      position: static;

      display: flex;
      justify-content: flex-start;

      .enter-span {
        display: none;
      }
    }
  }
  @media (max-width: 500px) {
    .enter-span {
      display: block;
      width: 0;
      height: 0;
    }
    &.sticky-text {
      .enter-span {
        display: block;
        width: 0;
        height: 0;
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    b {
      min-height: 52px;
      font-size: 24px;
    }
    span {
      margin-right: 6px;
      padding: 14px;
    }

    p {
      margin: 14px 0 0;
      color: ${(props) => (props.isWeb ? "#fff" : "#68686D")};

      font-size: 16px;
      line-height: 24px;

      span {
        color: ${colors.Body00};
      }
    }
    .enter-span {
      display: none;
    }
    &.sticky-text {
      position: static;

      display: flex;
      justify-content: flex-start;

      .enter-span {
        display: none;
      }
    }
  }
  @media (max-width: 360px) {
    &.sticky-text {
      .enter-span {
        display: block;
        width: 0;
        height: 0;
      }
    }
  }
`;
const SectionImageContent = styled.div`
  margin: 80px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  font-weight: "bold";

  img {
    width: 48%;
  }

  .image-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    width: 100%;
    height: 146px;
    color: #fff;

    font-size: 24px;
    font-weight: bold !important;
    background-color: ${colors.mainG};
    background-image: url(${ServiceInfoImage});
    background-position: right center;
    background-size: auto 146px;
    background-repeat: no-repeat;
    border-radius: 40px;
  }

  @media ${(props) => props.theme.tablet} {
    flex-direction: column;

    img {
      margin-bottom: 40px;
      width: 100%;
    }

    .image-content {
      margin: 0;
      font-size: 16px;
      background-position: right center;
      height: 73px;
      border-radius: 15px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin: 60px 0 0;

    .image-content {
      margin: 0;
      height: 73px;
      font-size: 16px;
      background-position: right center;
      border-radius: 15px;
    }
  }
`;

const ScrollSectionContainer = styled.div`
  margin: 0 auto;
  margin-top: 150px;
  padding-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  box-sizing: border-box;
  word-break: keep-all;
  white-space: pre-wrap;

  background-color: #f9f9f9;
  background-repeat: no-repeat;

  @media ${(props) => props.theme.tablet} {
    padding: 0px;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 0px;
  }
`;

const ScrollRow = styled.div`
  margin: 0 auto;
  padding: 0 80px;
  margin-top: ${(props) => (props.isMargin ? "30px" : 0)};
  display: flex;
  max-width: 1200px;
  align-items: start;
  justify-content: center;
  vertical-align: top;
  flex-wrap: wrap;
  flex-direction: row;

  @media ${(props) => props.theme.tablet} {
    padding-left: 5px;
    padding-right: 5px;
  }
  @media ${(props) => props.theme.mobile} {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

const ScrollContent = styled.div`
  width: 50%;
  display: inline-flex;
  vertical-align: top;

  img {
    min-width: ${(props) => (props.theme.tablet ? "200px" : "400px")};
    width: 100%;
    flex-wrap: wrap;

    &:last-child {
      margin-bottom: 0;
      display: flex;
    }
  }

  @media ${(props) => props.theme.tablet} {
    margin-top: 30px;
    padding: 0px;
    width: 100%;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 30px;
    padding: 0px;
    width: 100%;
  }
`;

const ScrollTextColor = styled.div`
  color: ${colors.Primary00};
  font-weight: bold;
  font-size: ${(props) => (props.innerWidth > 1000 ? "24px" : "20px")};
  line-height: 1.5;
`;

const WebImageContent = styled.div`
  margin-top: 20px;
  margin-left: 6vw;
  max-width: 80vw;
  min-width: 60vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  align-items: left;

  img {
    width: 60%;
    z-index: 0;
  }

  @media ${(props) => props.theme.tablet} {
    flex-direction: column;

    img {
      width: 100%;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin: 60px 0 0;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 558px;
  background-color: #28272f;
  box-sizing: border-box;
  z-index: 5;

  p {
    margin: 0 0 40px;
    color: #fff;
    font-size: 42px;
    line-height: 55px;
    font-weight: bold;
    text-align: center;

    span {
      color: ${colors.G5};
    }
  }

  button {
    width: 418px;
    height: 76px;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    border-radius: 40px;
    background-color: ${colors.B2};
    border: 0;
    cursor: pointer;
  }

  @media ${(props) => props.theme.tablet} {
    height: 467px;

    p {
      margin: 0 0 50px;
      font-size: 34px;
      line-height: 47px;
    }
  }
  @media (max-width: 500px) {
    button {
      width: 85%;
    }
  }
  @media ${(props) => props.theme.mobile} {
    height: 315px;

    p {
      margin: 0 0 50px;
      font-size: 24px;
      line-height: 33px;
    }
    button {
      width: calc(100%-40px);
      height: 66px;
      font-size: 16px;
    }
  }
`;
