import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        box-sizing: border-box;
    }
    body {
        font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        margin: 0;
        padding: 0;
    }
    ul, li {
        list-style: none;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    html, body, #root {
        height: 100%;
        font-size: 62.5%; 
    }
    iframe {
        position: relative;
        width: 320px !important;
        height: 680px !important;
    }
`;
