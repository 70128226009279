import React from "react";
import styled from "styled-components";

//images
import Main1440 from "../assets/about_main_1440.png";
import DownOn from "../assets/ic__down_on_80px.png";

const Front = (props) => {
  const { innerWidth } = props;
  let contentTitleFontSize;
  let contentTitleLineHeight;

  if (innerWidth > 744) {
    contentTitleFontSize = 52;
    contentTitleLineHeight = 70;
  } else if (innerWidth <= 744 && innerWidth > 500) {
    contentTitleFontSize = 54;
    contentTitleLineHeight = 72;
  } else if (innerWidth <= 500 && innerWidth > 375) {
    contentTitleFontSize = 52 - 11 * ((500 - innerWidth) / (500 - 375));
    contentTitleLineHeight = 70 - 21 * ((500 - innerWidth) / (500 - 375));
  } else {
    contentTitleFontSize = 40;
    contentTitleLineHeight = 50;
  }
  return (
    <MainImage>
      <Container>
        <Title1920
          contentTitleFontSize={contentTitleFontSize}
          contentTitleLineHeight={contentTitleLineHeight}
        >
          일하는 누구나 존중 받을 수 있는 새로운 근로 생태계를 꿈꿉니다
        </Title1920>
        <Title744
          contentTitleFontSize={contentTitleFontSize}
          contentTitleLineHeight={contentTitleLineHeight}
        >
          <span>
            일하는 누구나
            <br />
            존중 받을 수 있는
            <br />
            새로운 근로 생태계를 꿈꿉니다
          </span>
        </Title744>
        <Title375
          contentTitleFontSize={contentTitleFontSize}
          contentTitleLineHeight={contentTitleLineHeight}
        >
          <span>
            일하는 누구나
            <br />
            존중 받을 수 있는
            <br />
            새로운 근로
            <br />
            생태계를 꿈꿉니다
          </span>
        </Title375>
        <Content1920>
          <span>
            거래 과정으로 인한 피로도를 줄이고 업무에 집중할 수 있는 환경을
            만들고자 합니다.
            <br />
            잦은 의사 번복, 단순 변심으로 인한 논쟁은 줄이고
            <br />
            고객과 작업자 양방이 만족할 수 있는 거래를 페이워크가 돕습니다.
          </span>
        </Content1920>
        <Content744>
          <span>
            <span style={{ display: "block", maxWidth: "480px" }}>
              거래 과정으로 인한 피로도를 줄이고 업무에 집중할 수 있는 환경을
              만들고자 합니다.
            </span>
            잦은 의사 번복, 단순 변심으로 인한 논쟁은 줄이고 고객과 작업자
            양방이 만족할 수 있는 거래를 페이워크가 돕습니다.
          </span>
        </Content744>
      </Container>
      {/* <ButtonContainer>
        <DownButton
        // onClick={() => {
        //   window.scrollTo(0, window.innerHeight);
        // }}
        />
      </ButtonContainer> */}
    </MainImage>
  );
};

export default Front;

const MainImage = styled.section`
  height: 100vh;
  min-height: 1000px;
  width: 100%;
  background-image: url(${Main1440});
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  /* background-position: cover; */
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${(props) => props.theme.mobile} {
    display: block;
    min-height: 800px;
    height: 800px;
    margin-top: -58px;
    width: 375px;
  }
`;

const Container = styled.div`
  width: 1200px;
  margin: 218px 100px 0px;
  display: flex;
  flex-direction: column;
  /* z-index: 1; */
  @media ${(props) => props.theme.tablet} {
    margin: 168px 50px 0px;
  }
  @media ${(props) => props.theme.mobile} {
    margin: 88px 20px 0px;
    width: auto;
  }
`;

const Title1920 = styled.div`
  max-width: 650px;
  font-size: ${(props) => `${props.contentTitleFontSize}px`};
  line-height: ${(props) => `${props.contentTitleLineHeight}px`};
  font-weight: 600;
  color: #fff;
  word-break: keep-all;
  @media ${(props) => props.theme.tablet} {
    display: none;
  }
  /* @media ${(props) => props.theme.mobile} {
    
  } */
`;
const Title744 = styled.div`
  display: none;
  max-width: 490px;
  font-size: ${(props) => `${props.contentTitleFontSize}px`};
  line-height: ${(props) => `${props.contentTitleLineHeight}px`};
  font-weight: 600;
  color: #fff;
  word-break: keep-all;
  @media ${(props) => props.theme.tablet} {
    display: block;
  }
  @media ${(props) => props.theme.mobile} {
    display: none;
  }
`;
const Title375 = styled.div`
  display: none;

  @media ${(props) => props.theme.mobile} {
    display: block;
    min-width: 335px;
    font-size: ${(props) => `${props.contentTitleFontSize}px`};
    line-height: ${(props) => `${props.contentTitleLineHeight}px`};
    font-weight: 600;
    color: #fff;
    word-break: keep-all;
  }
`;

const Content1920 = styled.div`
  margin-top: 60px;
  font-size: 24px;
  line-height: 38px;
  font-weight: 600;
  color: #fff;
  word-break: keep-all;

  @media ${(props) => props.theme.tablet} {
    display: none;
  }
  /* @media ${(props) => props.theme.mobile} {
    
  } */
`;

const Content744 = styled.div`
  display: none;

  @media ${(props) => props.theme.tablet} {
    display: block;
    margin-top: 20px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: #fff;
    min-width: 335px;
    word-break: keep-all;
  }

  @media ${(props) => props.theme.mobile} {
    word-break: normal;
  }
`;

const ButtonContainer = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  bottom: 0px;

  @media ${(props) => props.theme.tablet} {
    display: block;
  }
`;

const DownButton = styled.div`
  @media ${(props) => props.theme.tablet} {
    margin: auto;
    width: 80px;
    height: 80px;
    background-image: url(${DownOn});
    background-size: cover;
  }
  /* @media ${(props) => props.theme.mobile} {
    
  } */
`;
