import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Main from "./component/Main";

const Billing = () => {
  return (
    <>
      <Header
        backgroundColor={"rgba(255, 255, 255, 1)"}
        backDropFilter={"blur(0px)"}
        isWhite={true}
      />
      <Main />
      <Footer />
    </>
  );
};

export default Billing;
