import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// tools
import styled from "styled-components";

// globals
import colors from "../globals/colors";

// images
import ArrowImg from "../assets/images/footer/arrow-right.png";
import FacebookImg from "../assets/images/footer/ic_logo_facebook.png";
import InstagramImg from "../assets/images/footer/ic_logo_instagram.png";
import AppStoreImg from "../assets/images/footer/ic_appStore.png";
import AppStoreHoverImg from "../assets/images/footer/ic_appStore_hover.png";
import GooglePlayImg from "../assets/images/footer/ic_googlePlay.png";
import GooglePlayHoverImg from "../assets/images/footer/ic_googlePlay_hover.png";

//tools
import Amplitude from "amplitude-js";

const Footer = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [isDownload, setIsDownload] = useState(false);
  const [appleIcon, setAppleIcon] = useState(AppStoreImg);
  const [googleIcon, setGoogleIcon] = useState(GooglePlayImg);
  const fontSizeTM =
    innerWidth >= 744 ? 14 : 14 - 2 * ((743 - innerWidth) / (743 - 375));
  // const sideMargin =
  //   innerWidth > 1454
  //     ? 120
  //     : innerWidth >= 744
  //     ? 120 - 70 * ((1454 - innerWidth) / (1454 - 744))
  //     : 50 - 30 * ((743 - innerWidth) / (743 - 375));

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onClickDownload = () => {
    setIsDownload(!isDownload);
  };

  useEffect(() => {
    const onClickOutsideClose = (e) => {
      if (!e.target.closest(".downloader") && isDownload) {
        setIsDownload(false);
      }
    };

    window.addEventListener("click", onClickOutsideClose);

    return () => {
      window.removeEventListener("click", onClickOutsideClose);
    };
  }, [isDownload]);

  const onClickAppStoreButton = () => {
    window.open("https://itunes.apple.com/app/id1547557352", "_blank");

    Amplitude.getInstance().logEvent("22.02_홈화면_하단_앱스토어다운로드_클릭");

    Amplitude.getInstance().logEvent("download", {
      OS: "ios",
      position: "bottom",
    });
  };

  const onClickPlayStoreButton = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=io.paywork.mobile",
      "_blank"
    );
    Amplitude.getInstance().logEvent(
      "22.02_홈화면_하단_플레이스토어다운로드_클릭"
    );

    Amplitude.getInstance().logEvent("download", {
      OS: "andoird",
      position: "bottom",
    });
  };

  return (
    <Container>
      <Wrapper
      // sideMargin={sideMargin}
      >
        <Header>
          <LeftHeader>
            <AboutLeft>
              <Link
                to="/about"
                onClick={() => {
                  window.wcs.event("footer", "about");
                  Amplitude.getInstance().logEvent(
                    "22.02_홈화면_회사소개_클릭"
                  );
                }}
              >
                회사소개
              </Link>
            </AboutLeft>
            <BlogLeft
              onClick={() => {
                window.wcs.event("footer", "blog");
                window.open("https://blog.paywork.io/", "_blank");
                Amplitude.getInstance().logEvent("22.02_홈화면_블로그_클릭");
              }}
            >
              블로그
              <img src={ArrowImg} alt="" />
            </BlogLeft>
          </LeftHeader>
          <RightHeader>
            <FBAndIGRight>
              <FBRight
                src={FacebookImg}
                onClick={() => {
                  window.wcs.event("footer", "facebook");
                  window.open("https://www.facebook.com/paywork.io/", "_blank");
                  Amplitude.getInstance().logEvent(
                    "22.02_홈화면_페이스북_클릭"
                  );
                }}
              />
              <IGRight
                src={InstagramImg}
                onClick={() => {
                  window.wcs.event("footer", "instagram");
                  window.open(
                    "https://www.instagram.com/paywork.io/",
                    "_blank"
                  );
                  Amplitude.getInstance().logEvent(
                    "22.02_홈화면_인스타그램_클릭"
                  );
                }}
              />
            </FBAndIGRight>
            <DownloadRight onClick={onClickDownload}>
              앱 다운로드
              <ArrowDownload isDownload={isDownload} />
              <DownLoader className="downloader" isDownload={isDownload}>
                <AppDownloader
                  src={appleIcon}
                  onMouseOver={() => setAppleIcon(AppStoreHoverImg)}
                  onMouseOut={() => setAppleIcon(AppStoreImg)}
                  onClick={onClickAppStoreButton}
                />
                <LineDownloader />
                <GoogleDownloader
                  src={googleIcon}
                  onMouseOver={() => setGoogleIcon(GooglePlayHoverImg)}
                  onMouseOut={() => setGoogleIcon(GooglePlayImg)}
                  onClick={onClickPlayStoreButton}
                />
              </DownLoader>
            </DownloadRight>
          </RightHeader>
        </Header>
        <Contents>
          <NumberAndEmail
            fontSizeTM={fontSizeTM}
          >{`문의 02-1833-4080   |   start@paywork.io`}</NumberAndEmail>
          <Address fontSizeTM={fontSizeTM}>
            <HeadAddress>
              {`본점 | 서울시 강남구 테헤란로217, 오렌지플래닛 7층`}
            </HeadAddress>
            <BranchAddress>
              {`지점 | 경기도 화성시 서신면 전곡리 전곡산단로 1093-6, 2층`}
            </BranchAddress>
          </Address>
          <TabletBusiness>
            <CompanyTabletBusiness>
              <NotClickCompanyTabletBusiness>
                {`페이워크 주식회사   |   대표자 손지인   |   사업자등록번호 893-81-01579   |   통신판매업 신고번호  2020-서울강남-00205   |   `}
              </NotClickCompanyTabletBusiness>

              <ClickCompanyTabletBusiness
                onClick={() => {
                  window.wcs.event("footer", "privacyPolicy");
                  window.open(
                    "https://paywork.notion.site/395dec4f9b5e4c95af4a2bc35acc66db",
                    "_blank"
                  );
                }}
              >
                {`개인정보처리방침`}
              </ClickCompanyTabletBusiness>
            </CompanyTabletBusiness>
            <DivisionLineTabletBusiness>{`   |   `}</DivisionLineTabletBusiness>
            <ServiceTabletBusiness
              onClick={() => {
                window.wcs.event("footer", "termsOfService");
                window.open(
                  "https://paywork.notion.site/a0953bd1404e455fa01cff58dd116e07",
                  "_blank"
                );
              }}
            >
              {`서비스이용약관`}
            </ServiceTabletBusiness>
          </TabletBusiness>

          <Business fontSizeTM={fontSizeTM}>
            <CompanyAndBusiness>
              <CompanyBusiness>{`페이워크 주식회사   |   대표자 손지인`}</CompanyBusiness>
              <DivisionLine1>{`   |   `}</DivisionLine1>
              <NumberBusiness>{`사업자등록번호 893-81-01579`}</NumberBusiness>
            </CompanyAndBusiness>
            <DivisionLine2>{`   |   `}</DivisionLine2>
            <NumberBusiness>{`통신판매업 신고번호  2020-서울강남-00205`}</NumberBusiness>
            <PrivacyAndService>
              <DivisionLine2>{`   |   `}</DivisionLine2>
              <PrivacyPolicy
                onClick={() =>
                  window.open(
                    "https://paywork.notion.site/395dec4f9b5e4c95af4a2bc35acc66db",
                    "_blank"
                  )
                }
              >
                개인정보처리방침
              </PrivacyPolicy>
              <DivisionLine3>{`   |   `}</DivisionLine3>
              <ServiceTerms
                onClick={() =>
                  window.open(
                    "https://paywork.notion.site/a0953bd1404e455fa01cff58dd116e07",
                    "_blank"
                  )
                }
              >
                서비스이용약관
              </ServiceTerms>
            </PrivacyAndService>
          </Business>
        </Contents>
      </Wrapper>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  background: ${colors.G8};
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${(props) => props.theme.mobile} {
    /* width: 360px; */
    width: 375px;
    display: block;
  }
`;

const Wrapper = styled.div`
  margin: 60px 120px 100px;
  width: 1200px;
  @media (max-width: 1454px) {
    width: 100%;
  }
  @media ${(props) => props.theme.tablet} {
    margin: 60px 50px 120px;
  }
  @media (max-width: 540px) {
    margin-bottom: 120px;
  }
  @media ${(props) => props.theme.mobile} {
    /* margin: 60px 20px 120px; */
    margin: 0px 20px;
    padding-top: 60px;
    width: auto;
    height: 420px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 540px) {
    flex-direction: column;
  }
`;

const LeftHeader = styled.div`
  font-size: 14px;
  color: ${colors.Body00};
  display: flex;
  margin-top: 8px;
  @media (max-width: 540px) {
    flex-direction: column;
  }
`;

const AboutLeft = styled.div`
  cursor: pointer;
  @media (max-width: 450px) {
    width: 55px;
  }
`;

const BlogLeft = styled.div`
  cursor: pointer;
  margin-left: 40px;
  display: flex;
  img {
    width: 16px;
    height: 16px;
  }
  @media (max-width: 540px) {
    margin-left: 0px;
    margin-top: 28px;
    width: 55px;
  }
`;

const RightHeader = styled.div`
  display: flex;
  @media (max-width: 540px) {
    margin-top: 40px;
  }
`;

const FBAndIGRight = styled.div`
  display: flex;
`;

const FBRight = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const IGRight = styled.img`
  width: 30px;
  height: 30px;
  margin-left: 30px;
  cursor: pointer;
  @media (max-width: 540px) {
    margin-left: 20px;
  }
`;

const DownloadRight = styled.div`
  width: 99px;
  height: 28px;
  background: #ffffff;
  border: 1px solid ${colors.G4};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-left: 30px;
  cursor: pointer;
  position: relative;
  @media (max-width: 540px) {
    margin-left: 20px;
  }
`;

const ArrowDownload = styled.div`
  display: flex;
  align-items: center;
  width: 6px;
  height: 6px;
  border-top: 1px solid ${colors.Body00};
  border-right: 1px solid ${colors.Body00};
  transform: ${(props) =>
    props.isDownload ? "rotate(315deg)" : "rotate(135deg)"};
  margin-left: 4px;
  margin-bottom: ${(props) => (props.isDownload ? "-4px" : "4px")};
`;

const DownLoader = styled.div`
  display: ${(props) => (props.isDownload ? "flex" : "none")};
  /* display: ${({ isDownload }) => (isDownload ? "flex" : "none")}; */
  width: 300px;
  height: 53px;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 40px;
  filter: drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.07));
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  @media (max-width: 1600px) {
    left: -178px;
  }
  @media (max-width: 540px) {
    left: -90px;
  }
`;

const AppDownloader = styled.img`
  width: 109px;
  height: 24.79px;
`;

const GoogleDownloader = styled.img`
  width: 126px;
  height: 22.28px;
`;

const LineDownloader = styled.div`
  width: 1px;
  height: 37px;
  background: ${colors.G5};
`;

const Contents = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  @media (max-width: 540px) {
    margin-top: 40px;
  }
`;

const NumberAndEmail = styled.div`
  display: flex;
  color: ${colors.G3};
  font-size: 14px;
  white-space: pre;
  /* @media (max-width: 743px) {
    font-size: 12px;
  } */
  @media ${(props) => props.theme.tablet} {
    font-size: ${(props) => `${props.fontSizeTM}px`};
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 12px;
  }
`;

const Address = styled.div`
  margin-top: 20px;
  color: ${colors.G3};
  font-size: 14px;
  display: flex;
  flex-direction: column;
  @media ${(props) => props.theme.tablet} {
    font-size: ${(props) => `${props.fontSizeTM}px`};
  }
  @media (max-width: 743px) {
    margin-top: 24px;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 12px;
  }
`;

const HeadAddress = styled.div`
  word-break: keep-all;
`;

const BranchAddress = styled.div`
  margin-top: 6px;
`;

const Business = styled.div`
  margin-top: 20px;
  color: ${colors.G3};
  font-size: 14px;
  white-space: pre;
  display: none;
  @media ${(props) => props.theme.tablet} {
    display: flex;
    font-size: ${(props) => `${props.fontSizeTM}px`};
    flex-direction: row;
  }
  @media (max-width: 719px) {
    flex-direction: column;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 12px;
  }
`;

const CompanyBusiness = styled.div``;

const NumberBusiness = styled.div`
  margin-top: 0px;
  @media (max-width: 450px) {
    margin-top: 6px;
  }
`;

const DivisionLine1 = styled.div`
  display: block;
  @media (max-width: 865px) {
    display: none;
  }
  @media ${(props) => props.theme.tablet} {
    display: block;
  }
  @media (max-width: 450px) {
    display: none;
  }
`;

const DivisionLine2 = styled.div`
  display: block;
  @media (max-width: 719px) {
    display: none;
  }
`;

const DivisionLine3 = styled.div`
  display: block;
`;

const PrivacyPolicy = styled.div`
  cursor: pointer;
`;

const ServiceTerms = styled.div`
  cursor: pointer;
`;

const PrivacyAndService = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  @media (max-width: 719px) {
    margin-top: 6px;
  }
`;

const TabletBusiness = styled.div`
  display: flex;
  color: ${colors.G3};
  font-size: 14px;
  margin-top: 20px;
  white-space: pre;
  @media (max-width: 865px) {
    flex-direction: column;
  }
  @media ${(props) => props.theme.tablet} {
    display: none;
  }
`;

const CompanyTabletBusiness = styled.div`
  display: flex;
`;
const NotClickCompanyTabletBusiness = styled.div``;
const ClickCompanyTabletBusiness = styled.div``;
const DivisionLineTabletBusiness = styled.div`
  display: block;
  @media (max-width: 865px) {
    display: none;
  }
`;

const ServiceTabletBusiness = styled.div`
  margin-top: 0px;
  @media (max-width: 865px) {
    margin-top: 6px;
  }
`;

const CompanyAndBusiness = styled.div`
  display: flex;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;
